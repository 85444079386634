import { Occupant, VehicleColor, VehicleType } from "common/graphql/queries/GetRTOMapData";

export const formatVehicleColor = (color: VehicleColor): string => {
  const breaks = color.split("-");

  return breaks.join(" ");
};

export const formatVehicleType = (type: VehicleType): string => {
  const breaks = type.split("-");

  return breaks[0];
};

export const serializeOccupant = (occupant: Occupant): string => {
  return `${formatVehicleColor(occupant.vehicle.color)} ${formatVehicleType(occupant.vehicle.type)} ${String(
    occupant.index,
  ).padStart(2, "0")}`;
};
