import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Stack from "@mui/material/Stack";
import TransactionHeader from "@app.automotus.io/components/TransactionHeader";
import TransactionDetail from "@app.automotus.io/components/TransactionDetail";
import Paper from "@mui/material/Paper";
import {
  PayeeDailyTransactionsTableRow,
  PayeeTransactionsTable,
  PayeeWalletTransactionsTable,
} from "@app.automotus.io/components/PayeeTables";
import parseDate from "date-fns/parse";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { PayeeTableDisplayMode } from "@app.automotus.io/components/PayeeTables/PayeeTableToolbar";
import startOfMonth from "date-fns/startOfMonth";
import { useLazyQuery } from "@apollo/client";
import {
  GET_PAYEE_TRANSACTIONS_FOR_DAY,
  GET_PAYEE_TRANSACTIONS_POTENTIAL_REVENUE_FILTER,
  GET_PAYEE_TRANSACTIONS_PRICE_FILTER,
  GET_PAYEE_TRANSACTIONS_SUMMARY,
  GetPayeeTransactionsForDayData,
  GetPayeeTransactionsForDayVars,
  GetPayeeTransactionsSummaryData,
  GetPayeeTransactionsSummaryVars,
  PayeeTransactionsDailySummary,
  TransactionOrderBy,
} from "common/graphql";
import formatDate from "date-fns/format";
import endOfMonth from "date-fns/endOfMonth";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import { sortBy } from "lodash";
import generateDatesInRange from "common/helpers/generateDatesInRange";
import { GridSortModel } from "@mui/x-data-grid-pro";
import { parseSortModel, serializeSortModel } from "common/graphql/queryString";

export const Dashboard: React.FC = () => {
  const [now] = React.useState(new Date());
  const { userProfile } = useUserProfile();
  const [searchParams, setSearchParams] = useSearchParams();
  const [transactionId, setTransactionId] = React.useState<string>("");

  const tab = searchParams.get("tab") || "digital_wallet_transactions";

  const selectedDate = new Date(searchParams.get("date") || startOfMonth(now));
  const setSelectedDate = (newDate: Date) => {
    searchParams.set("date", newDate.toISOString());
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams?.get("transactionId")) {
      setTransactionId(searchParams?.get("transactionId") as string);
    }
  }, [searchParams]);
  const tableDisplayMode: PayeeTableDisplayMode = (searchParams.get("displayMode") as PayeeTableDisplayMode) || "month";
  const setTableDisplayMode = (newDisplayMode: PayeeTableDisplayMode) => {
    searchParams.set("displayMode", newDisplayMode);
    setSearchParams(searchParams);
  };

  const isDemoUser = !!(userProfile?.email || "").match(/\+demo@automotus.co/);

  const selectedDateEpoch = selectedDate.getTime();

  const selectTransactionId = (id: string) => {
    searchParams.set("transactionId", id);
    setSearchParams(searchParams);
    setTransactionId(id);
  };

  const [
    fetchTransactions,
    { data: transactionsData, error: transactionsError, loading: transactionsLoading, refetch: refetchTransactions },
  ] = useLazyQuery<GetPayeeTransactionsForDayData, GetPayeeTransactionsForDayVars>(GET_PAYEE_TRANSACTIONS_FOR_DAY, {
    onError(error) {
      console.error(error);
    },
  });

  const [fetchSummary, { data: summaryData, loading: summaryLoading, error: summaryError, refetch: refetchSummary }] =
    useLazyQuery<GetPayeeTransactionsSummaryData, GetPayeeTransactionsSummaryVars>(GET_PAYEE_TRANSACTIONS_SUMMARY, {
      onError(error) {
        console.error(error);
      },
    });

  const page = Number(searchParams.get("page") || 1);
  const handlePageChange = (newPage: number) => {
    searchParams.set("page", `${newPage}`);
    setSearchParams(searchParams);
  };

  const sortStr =
    searchParams.get("sort") ||
    (tableDisplayMode === "month"
      ? "date+desc"
      : tab === "digital_wallet_transactions"
      ? "transactionIndex+desc"
      : "timeRange+desc");
  const sortModel: GridSortModel = parseSortModel(sortStr);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    searchParams.set("sort", serializeSortModel(newSortModel));
    setSearchParams(searchParams);
  };

  const parseOrderBy = (sortModel: GridSortModel): TransactionOrderBy[] => {
    const { field: sortField, sort: sortDirection } = sortModel[0];
    switch (sortField) {
      case "transactionIndex":
        return [{ created_at: sortDirection || "desc" }];
      case "timeRange":
        return [{ end_time: sortDirection || "desc" }];
      default:
        return [{ date: sortDirection || "desc" }];
    }
  };
  const orderByString = JSON.stringify(parseOrderBy(sortModel));

  React.useEffect(() => {
    if (tableDisplayMode === "day") {
      const orderBy = JSON.parse(orderByString);
      fetchTransactions({
        variables: {
          day: formatDate(selectedDateEpoch, "yyyy-MM-dd"),
          transactionType: tab === "digital_wallet_transactions" ? "gateway_transaction" : "park",
          priceFilter: isDemoUser
            ? GET_PAYEE_TRANSACTIONS_POTENTIAL_REVENUE_FILTER
            : GET_PAYEE_TRANSACTIONS_PRICE_FILTER,
          offset: (page - 1) * 31,
          limit: 31,
          orderBy,
        },
      });
    } else {
      const since = startOfMonth(selectedDateEpoch);
      const until = endOfMonth(selectedDateEpoch);

      fetchSummary({
        variables: {
          since: formatDate(since, "yyyy-MM-dd"),
          until: formatDate(until, "yyyy-MM-dd"),
        },
      });
    }
  }, [
    selectedDateEpoch,
    tableDisplayMode,
    fetchTransactions,
    fetchSummary,
    tab,
    isDemoUser,
    page,
    sortStr,
    orderByString,
  ]);

  const handleClickBack = () => {
    if (transactionId) {
      searchParams.delete("transactionId");
      setSearchParams(searchParams);
      setTransactionId("");
      return;
    }

    if (tableDisplayMode === "day") {
      setTableDisplayMode("month");
      return;
    }
  };

  const gapFillStart = startOfMonth(selectedDateEpoch);
  const endOfToday = endOfDay(new Date());
  const endOfSelectedMonth = endOfMonth(selectedDateEpoch);
  // We need to make sure that we don't display future days in the summary, so we choose the lesser date of the current
  // day and the end of the selected month to decide the end of our gapfill.
  const gapFillEnd = isAfter(endOfSelectedMonth, endOfToday) ? endOfToday : endOfSelectedMonth;

  return (
    <>
      <IconButton
        onClick={handleClickBack}
        sx={{ visibility: Boolean(transactionId) || tableDisplayMode === "day" ? "visible" : "hidden" }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Stack spacing={3} width="100%">
        <ContentTitle displayMode={transactionId ? "transaction" : tableDisplayMode} />
        {transactionId ? (
          <Stack spacing={3}>
            <TransactionHeader transactionId={transactionId} />
            <TransactionDetail transactionId={transactionId} />
          </Stack>
        ) : (
          <Paper sx={{ width: "100%" }}>
            {tab === "digital_wallet_transactions" ? (
              <PayeeWalletTransactionsTable
                loading={tableDisplayMode === "month" ? summaryLoading : transactionsLoading}
                displayMode={tableDisplayMode}
                page={page - 1}
                onPageChange={handlePageChange}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                rows={
                  tableDisplayMode === "month"
                    ? gapFillSummaries(
                        gapFillStart,
                        gapFillEnd,
                        summaryData?.dailySummaries || [],
                        ({ walletTransactionsAmount }) => walletTransactionsAmount,
                      )
                    : transactionsData?.transactions?.map((t) => ({
                        transactionId: t.transactionId,
                        transactionIndex: t.transaction.index,
                        transactionType:
                          t.transaction.gatewayTransactions[0]?.gatewayTransactionType || "wallet_reload",
                        amount: t.price,
                      })) || []
                }
                selectedDate={selectedDate}
                onChangeSelectedDate={(newDate) => setSelectedDate(newDate)}
                maxDate={now}
                onClickTryAgain={() => (tableDisplayMode === "month" ? refetchSummary() : refetchTransactions())}
                error={tableDisplayMode === "month" ? summaryError : transactionsError}
                onClickDateRow={(params) => {
                  setSelectedDate(params.row.date);
                  setTableDisplayMode("day");
                }}
              />
            ) : (
              <PayeeTransactionsTable
                loading={tableDisplayMode === "month" ? summaryLoading : transactionsLoading}
                displayMode={tableDisplayMode}
                page={page - 1}
                onPageChange={handlePageChange}
                sortModel={sortModel}
                onSortModelChange={handleSortModelChange}
                rows={
                  tableDisplayMode === "month"
                    ? gapFillSummaries(
                        gapFillStart,
                        gapFillEnd,
                        summaryData?.dailySummaries || [],
                        ({ potentialRevenueAmount, registeredTransactionsAmount }) => {
                          return Math.abs(isDemoUser ? potentialRevenueAmount : registeredTransactionsAmount);
                        },
                      )
                    : transactionsData?.transactions?.map(
                        (t) =>
                          ({
                            transactionId: t.transactionId,
                            transactionIndex: t.transaction.index,
                            timeRange: {
                              start: new Date(t.startTime || 0),
                              end: new Date(t.endTime || 0),
                            },
                            addressStreet: t.addressStreet,
                            amount: Math.abs(isDemoUser ? t.potentialRevenue : t.price),
                          } as PayeeDailyTransactionsTableRow),
                      ) || []
                }
                selectedDate={selectedDate}
                onChangeSelectedDate={(newDate) => setSelectedDate(newDate)}
                maxDate={now}
                onClickTryAgain={() => (tableDisplayMode === "month" ? refetchSummary() : refetchTransactions())}
                error={tableDisplayMode === "month" ? summaryError : transactionsError}
                onClickDateRow={(params) => {
                  setSelectedDate(params.row.date);
                  setTableDisplayMode("day");
                }}
                onClickTransactionRow={(params) => {
                  selectTransactionId(params.row.transactionId);
                }}
              />
            )}
          </Paper>
        )}
      </Stack>
    </>
  );
};

const ContentTitle: React.FC<ContentTitleProps> = ({ displayMode }) => {
  const title =
    displayMode === "month" ? "Monthly Transactions" : displayMode === "day" ? "Dated Transactions" : "Park Event";
  const description =
    displayMode === "month"
      ? "Recent transactions can take up to 24 hours to process"
      : displayMode === "day"
      ? "Transactions processed for the selected date"
      : "A detailed description of a park event";

  return (
    <Box>
      <Typography variant={"h4"} fontWeight={600}>
        {title}
      </Typography>
      <Typography variant={"subtitle1"} color={"text.disabled"} fontSize={"0.75rem"}>
        {description}
      </Typography>
    </Box>
  );
};

/**
 * Fill gaps in date range missing from summary data
 * @param since
 * @param until
 * @param summaries
 * @param computeAmount
 */
const gapFillSummaries = (
  since: Date,
  until: Date,
  summaries: PayeeTransactionsDailySummary[],
  computeAmount: (summary: PayeeTransactionsDailySummary) => number,
) => {
  const daysInRange = generateDatesInRange(since, until);
  const sortedSummaries = sortBy(summaries, "date");

  // For each day in the range d,
  // If the summary date is greater than d, add an element with 0 amount to the result array for day d and increment d
  // If the summary date is the same as the day in range, add the element with the corresponding amount and increment both
  // d and summaryD
  let i = 0;
  let j = 0;
  const gapFilled = [];

  while (i < daysInRange.length) {
    const gapDate = daysInRange[i];

    if (j >= sortedSummaries.length) {
      gapFilled.push({ date: gapDate, amount: 0 });
      i++;
      continue;
    }

    const summaryDate = parseDate(sortedSummaries[j].date, "yyyy-MM-dd", new Date());

    if (isAfter(summaryDate, gapDate)) {
      gapFilled.push({ date: gapDate, amount: 0 });
      i++;
    } else {
      gapFilled.push({ date: summaryDate, amount: computeAmount(sortedSummaries[j]) });
      i++;
      j++;
    }
  }

  return gapFilled;
};

interface ContentTitleProps {
  displayMode: "month" | "day" | "transaction";
}
