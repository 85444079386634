import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_CITATION_BY_ID,
  GET_TRANSACTION_IMAGES,
  GetCitationByIdData,
  GetCitationByIdVars,
  GetTransactionImagesData,
  GetTransactionImagesVars,
} from "common/graphql";
import Paper from "@mui/material/Paper";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransactionImageCarousel from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionImageCarousel";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";

export const CitationImageCarousel: React.FC<CitationImageCarouselProps> = ({ citationId }) => {
  const {
    data: citationData,
    loading: citationLoading,
    error: citationError,
    refetch: refetchCitation,
  } = useQuery<GetCitationByIdData, GetCitationByIdVars>(GET_CITATION_BY_ID, {
    variables: { id: citationId },
    onError(err) {
      console.error("failed to load citation", err);
    },
  });

  const [getTransactionImages, { data: imagesData, loading: imagesLoading, error: getImagesError }] = useMutation<
    GetTransactionImagesData,
    GetTransactionImagesVars
  >(GET_TRANSACTION_IMAGES, {
    onError(err) {
      console.error("failed to fetch transaction images", err);
    },
  });

  const transactionId = citationData?.citation?.sessionTransaction?.id || "";

  useEffect(() => {
    getTransactionImages({ variables: { transactionId } });
  }, [getTransactionImages, transactionId]);

  const error = getImagesError || citationError;
  const loading = imagesLoading || citationLoading;

  return (
    <Paper sx={{ p: 3 }}>
      {error ? (
        <LocalLevelError
          onTryAgain={() => {
            getTransactionImages({ variables: { transactionId } });
            refetchCitation();
          }}
        />
      ) : (
        <Stack spacing={3}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <LoadingSkeleton loading={loading}>
              <Typography variant={"h5"} fontWeight={400}>
                Citation Number: {citationData?.citation?.citationNumber}
              </Typography>
            </LoadingSkeleton>
          </Box>
          <TransactionImageCarousel
            loading={loading}
            height={404}
            parkStartDate={
              citationData?.citation?.sessionTransaction &&
              new Date(citationData?.citation?.sessionTransaction.sessionStartedAtLocal || 0)
            }
            parkEndDate={
              citationData?.citation?.sessionTransaction &&
              new Date(citationData.citation.sessionTransaction.sessionEndedAtLocal || 0)
            }
            licensePlateCaptureDate={
              citationData?.citation?.sessionTransaction &&
              new Date(citationData.citation.sessionTransaction.sessionStartedAtLocal || 0)
            }
            parkStartImageSrc={imagesData?.transactionImages?.sessionStartImageUrl}
            parkEndImageSrc={imagesData?.transactionImages?.sessionEndImageUrl}
            licensePlateCaptureImageSrc={imagesData?.transactionImages?.licensePlateImageUrl}
          />
        </Stack>
      )}
    </Paper>
  );
};

export interface CitationImageCarouselProps {
  citationId: string;
}

export default CitationImageCarousel;
