import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { InvoiceStatusStepper } from "@app.automotus.io/components/InvoiceStatusStepper";
import { InvoiceVehicleDetails } from "@app.automotus.io/components/InvoiceVehicleDetails";
import { InvoiceParkEvents } from "@app.automotus.io/components/InvoiceParkEvents";
import { InvoiceGrandTotal } from "@app.automotus.io/components/InvoiceGrandTotal";
import { DownloadInvoicePDFButton } from "@app.automotus.io/components/DownloadInvoicePDFButton";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import Box from "@mui/material/Box";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { InvoicesLocationState } from "@curbsuite/routes/dashboard/invoices/index";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const Invoice: React.FC = () => {
  const { invoiceId = "" } = useParams<{ invoiceId: string }>();
  const { data, loading, error, refetch } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
    onError(err) {
      console.error(`failed to fetch invoice by id ${invoiceId}`, err);
    },
  });

  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as InvoicesLocationState | null;
  const backButtonLink = `..?page=${locationState?.invoiceTablePage || 0}&pageSize=${
    locationState?.invoiceTablePageSize || 31
  }${locationState?.invoiceTableSortStr ? `&sort=${encodeURIComponent(locationState?.invoiceTableSortStr)}` : ""}`;

  if (error) {
    return (
      <>
        <IconButton component={Link} to={backButtonLink}>
          <ArrowBackIosIcon />
        </IconButton>
        <Stack spacing={3} width={"100%"}>
          <Box>
            <LocalLevelError onTryAgain={refetch} />
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <>
      <IconButton component={Link} to={backButtonLink}>
        <ArrowBackIosIcon />
      </IconButton>
      <Stack spacing={3} width={"100%"}>
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"h4"} fontWeight={600}>
              Invoice Number: {`${data?.invoice?.index}`.padStart(6, "0")}
            </Typography>
          </LoadingSkeleton>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"subtitle1"} fontSize={12}>
              A detailed description of an invoice
            </Typography>
          </LoadingSkeleton>
        </Box>
        <InvoiceStatusStepper invoiceId={invoiceId} />
        <InvoiceVehicleDetails invoiceId={invoiceId} />
        <InvoiceParkEvents
          invoiceId={invoiceId}
          onClickParkEvent={(transactionId) => navigate(`transactions/${transactionId}`)}
        />
        <InvoiceGrandTotal invoiceId={invoiceId} />
        {data?.invoice?.voided_at && data?.invoice?.replaced_by_invoice ? (
          <Button
            variant={"contained"}
            sx={{ textTransform: "none" }}
            fullWidth
            component={Link}
            to={`/invoices/${data?.invoice?.replaced_by_invoice?.id}`}
          >
            View Revised Invoice
          </Button>
        ) : (
          <DownloadInvoicePDFButton invoiceId={invoiceId} />
        )}
      </Stack>
    </>
  );
};

export default Invoice;
