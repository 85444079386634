import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export const PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS = "digital_wallet_transactions";
export const PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS = "park_transactions";

export const PAYEE_OVERVIEW_TABS_VALUES_INVOICES = "invoices";

export const PAYEE_OVERVIEW_TABS_VALUES_CITATIONS = "citations";

export type PayeeOverviewTabValue = "digital_wallet_transactions" | "park_transactions" | "invoices" | "citations";

export const PAYEE_OVERVIEW_TABS_LABELS = {
  [PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS]: "Digital Wallet Transactions",
  [PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS]: "Park Transactions",
  [PAYEE_OVERVIEW_TABS_VALUES_INVOICES]: "Automated Invoices",
  [PAYEE_OVERVIEW_TABS_VALUES_CITATIONS]: "Citation Transactions",
};

export interface PayeeOverviewTabButtonProps {
  active: boolean;
  onClick: () => void;
  label: string;
  loading?: boolean;
}

export const PayeeOverviewTabDesktopButton: React.FC<PayeeOverviewTabButtonProps> = ({
  active,
  onClick,
  label,
  loading = false,
}) => {
  return loading ? (
    <Skeleton>
      <Box width="240px" height="45px" />
    </Skeleton>
  ) : (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        color: active ? "primary.main" : "text.secondary",
        textTransform: "none",
        width: "342px",
        borderBottom: active ? "1px solid" : "none",
        borderBottomColor: active ? "primary.main" : "none",
        borderRadius: "0px",
      }}
    >
      <Typography variant="body3" fontWeight={500}>
        {label}
      </Typography>
    </Button>
  );
};

export const PayeeOverviewTabs: React.FC<PayeeOverviewTabsProps> = ({ loading = false, tab, onChangeTab }) => {
  const { userProfile, loading: userProfileLoading } = useUserProfile();

  // TODO: make this dynamic
  let invoicesEnabled = false;
  if (userProfile?.account?.id === process.env.REACT_APP_BETHLEHEM_ACCOUNT_ID) {
    invoicesEnabled = true;
  }

  // TODO: make this dynamic
  let citationsEnabled = false;
  if (userProfile?.account?.id === process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID) {
    citationsEnabled = true;
  }

  // TODO: make this dynamic
  let digitalWalletTransactionsEnabled = false;
  if (userProfile?.account?.id !== process.env.REACT_APP_PIKEVILLE_ACCOUNT_ID) {
    digitalWalletTransactionsEnabled = true;
  }

  // TODO: make this dynamic
  let parkTransactionsEnabled = false;
  if (userProfile?.account?.id !== process.env.REACT_APP_PIKEVILLE_ACCOUNT_ID) {
    parkTransactionsEnabled = true;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
        borderBottom: loading || userProfileLoading ? "none" : "1px solid",
        borderBottomColor: loading || userProfileLoading ? "none" : "action.disabledBackground",
      }}
    >
      <Stack direction={"row"} spacing={loading ? 16 : 0}>
        {digitalWalletTransactionsEnabled && (
          <PayeeOverviewTabDesktopButton
            active={tab === PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS}
            onClick={() => onChangeTab(PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS)}
            label={PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_DIGITAL_WALLET_TRANSACTIONS]}
            loading={loading || userProfileLoading}
          />
        )}
        {parkTransactionsEnabled && (
          <PayeeOverviewTabDesktopButton
            active={tab === PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS}
            onClick={() => onChangeTab(PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS)}
            label={PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_PARK_TRANSACTIONS]}
            loading={loading || userProfileLoading}
          />
        )}
        {invoicesEnabled && (
          <PayeeOverviewTabDesktopButton
            active={tab === PAYEE_OVERVIEW_TABS_VALUES_INVOICES}
            onClick={() => onChangeTab(PAYEE_OVERVIEW_TABS_VALUES_INVOICES)}
            label={PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_INVOICES]}
            loading={loading || userProfileLoading}
          />
        )}
        {citationsEnabled && (
          <PayeeOverviewTabDesktopButton
            active={tab === PAYEE_OVERVIEW_TABS_VALUES_CITATIONS}
            onClick={() => onChangeTab(PAYEE_OVERVIEW_TABS_VALUES_CITATIONS)}
            label={PAYEE_OVERVIEW_TABS_LABELS[PAYEE_OVERVIEW_TABS_VALUES_CITATIONS]}
            loading={loading || userProfileLoading}
          />
        )}
      </Stack>
    </Box>
  );
};

export interface PayeeOverviewTabsProps {
  loading?: boolean;
  tab: PayeeOverviewTabValue;
  onChangeTab: (tab: PayeeOverviewTabValue) => void;
}

export default PayeeOverviewTabs;
