import React from "react";
import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import Button from "@mui/material/Button";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";

export const DownloadInvoicePDFButton: React.FC<DownloadInvoicePDFButtonProps> = ({ invoiceId }) => {
  const { data, loading } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  return (
    <LoadingSkeleton loading={loading}>
      {data?.invoice?.notice_content_url && (
        <Button
          variant={"contained"}
          sx={{ textTransform: "none" }}
          fullWidth
          href={data.invoice.notice_content_url}
          download
          target={"_blank"}
        >
          Download Invoice (PDF)
        </Button>
      )}
    </LoadingSkeleton>
  );
};

export interface DownloadInvoicePDFButtonProps {
  invoiceId: string;
}

export default DownloadInvoicePDFButton;
