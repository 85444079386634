import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridSortModel,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useQuery } from "@apollo/client";
import { TableErrorOverlay } from "@app.automotus.io/components/PayeeTables/TableErrorOverlay";
import { TableLoadingOverlay } from "@app.automotus.io/components/PayeeTables/TableLoadingOverlay";
import { formatCurrency } from "common/format";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomPagination } from "@app.automotus.io/components/CustomPagination";
import format from "date-fns/format";
import { CitationOrderBy, CitationSummary, GET_CITATIONS, GetCitationsData, GetCitationsVars } from "common/graphql";
import { parseSortModel, serializeSortModel } from "common/graphql/queryString";
import { getViolationTypeLabel } from "./utils";

export interface CitationsLocationState {
  citationTablePage?: number;
  citationTablePageSize?: number;
  citationTableSortStr?: string;
}

export const Citations: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
    pageSize: "31",
  });
  const navigate = useNavigate();

  const page = Number(searchParams.get("page") || 1);
  const handlePageChange = (newPage: number) => {
    searchParams.set("page", `${newPage}`);
    setSearchParams(searchParams);
  };

  const pageSize = Number(searchParams.get("pageSize") || 31);
  const handlePageSizeChange = (newPageSize: number) => {
    searchParams.set("pageSize", `${newPageSize}`);
    setSearchParams(searchParams);
  };

  const sortStr = searchParams.get("sort") || "noticeDate+desc";
  const sortModel: GridSortModel = parseSortModel(sortStr);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    searchParams.set("sort", serializeSortModel(newSortModel));
    setSearchParams(searchParams);
  };

  const handleNavigate = (id: string) => {
    navigate(id, {
      state: {
        citationTablePage: page,
        citationTablePageSize: pageSize,
        citationTableSortStr: sortStr,
      },
    });
  };

  return (
    <Stack spacing={3} width={"100%"}>
      <Box>
        <Typography variant={"h4"} fontWeight={600} letterSpacing={0.25}>
          Citations
        </Typography>
        <Typography variant={"subtitle1"} fontSize={"12px"} letterSpacing={0.4} color={"text.disabled"}>
          Recent transactions can take up to 24 hours to process.
        </Typography>
      </Box>
      <CitationTable
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onNavigate={handleNavigate}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </Stack>
  );
};

export const CitationTableToolbar: React.FC<CitationTableToolbarProps> = ({ exportFileName }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 52,
        px: 1.25,
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: exportFileName }}
        excelOptions={{ fileName: exportFileName }}
        sx={{
          fontSize: "16px",
          fontWeight: 400,
          letterSpacing: 0.15,
          textTransform: "none",
        }}
      />
    </Box>
  );
};

export interface CitationTableToolbarProps {
  exportFileName: string;
}

export const CitationTable: React.FC<CitationTableProps> = ({
  page,
  onPageChange,
  pageSize,
  onPageSizeChange,
  onNavigate,
  sortModel,
  onSortModelChange,
}) => {
  const CITATION_TABLE_COLUMNS = useMemo(
    () => [
      {
        field: "citationNumber",
        headerName: "Citation ID",
        width: 193,
        align: "left",
        headerAlign: "left",
        sortingOrder: ["desc", "asc"],
      } as GridColDef<CitationSummary, string>,
      {
        field: "violationType",
        headerName: "Violation Type",
        width: 166,
        sortable: false,
        valueFormatter({ value }) {
          return getViolationTypeLabel(value);
        },
      } as GridColDef<CitationSummary, string>,
      {
        field: "noticeDate",
        headerName: "Notice Date",
        type: "date",
        width: 159,
        valueGetter({ row }) {
          return new Date(row.noticeSentAt);
        },
        sortingOrder: ["desc", "asc"],
      } as GridColDef<CitationSummary, Date>,
      {
        field: "paidDate",
        headerName: "Paid Date",
        type: "date",
        width: 144,
        valueGetter({ row }) {
          return row.paidAt ? new Date(row.paidAt) : null;
        },
        valueFormatter({ value }) {
          return value ? format(value, "MM/dd/yyyy") : "-";
        },
        sortingOrder: ["desc", "asc"],
      } as GridColDef<CitationSummary, Date | null, string>,
      {
        field: "status",
        headerName: "Status",
        width: 169,
        valueGetter({ row }) {
          if (row.paidAt) {
            return "Paid";
          } else if (row?.dispute?.status === "pending") {
            return "Under Review";
          } else if (row.voidedAt) {
            return "Void";
          } else if (new Date(row.dueAt).getTime() < Date.now()) {
            return "Overdue";
          } else {
            return "Pending";
          }
        },
        sortable: false,
      } as GridColDef<CitationSummary, string>,
      {
        field: "amount",
        headerName: "Amount",
        width: 151,
        sortable: false,
        valueGetter({ row }) {
          return row.totalAmountDue;
        },
        valueFormatter({ value }) {
          return `$${formatCurrency(value)}`;
        },
      } as GridColDef<CitationSummary, number, string>,
      {
        field: "actions",
        type: "actions",
        sortable: false,
        getActions: (params) => [
          <GridActionsCellItem
            label={"View Citation"}
            icon={<ArrowForwardIosIcon sx={{ color: (theme) => theme.palette.action.active }} />}
            onClick={() => onNavigate(`${params.row.id}`)}
          />,
        ],
      } as GridActionsColDef,
    ],
    [onNavigate],
  );
  const offset = page * pageSize;
  const limit = pageSize;

  const parseOrderBy = (sortModel: GridSortModel): CitationOrderBy[] => {
    const { field: sortField, sort: sortDirection } = sortModel[0];
    switch (sortField) {
      case "citationNumber":
        return [{ citation_number: sortDirection || "asc" }];
      case "paidDate":
        return [{ paid_at: sortDirection === "asc" ? "asc_nulls_last" : "desc_nulls_last" }];
      default:
        return [{ notice_sent_at: sortDirection || "desc" }];
    }
  };

  const { data, loading, error, refetch } = useQuery<GetCitationsData, GetCitationsVars>(GET_CITATIONS, {
    variables: {
      offset,
      limit,
      orderBy: parseOrderBy(sortModel),
    },
    onError(err) {
      console.error("failed to fetch citations", err);
    },
  });
  const exportFileName = `citations_${(Math.random() * 1e6).toFixed(0)}`;

  return (
    <Paper sx={{ borderRadius: 3, maxWidth: 1084, height: 876 }}>
      <DataGridPro
        components={{
          ErrorOverlay: TableErrorOverlay,
          LoadingOverlay: TableLoadingOverlay,
          Toolbar: CitationTableToolbar,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            exportFileName: exportFileName,
          },
          errorOverlay: {
            onTryAgain: () => refetch(),
          },
          loadingOverlay: {
            numColumns: CITATION_TABLE_COLUMNS.length,
          },
        }}
        columns={CITATION_TABLE_COLUMNS}
        rows={data?.citations || []}
        rowCount={data?.citationsAggregate.aggregate.count || 0}
        loading={loading}
        disableMultipleColumnsSorting
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        error={error}
        pagination
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        paginationMode={"server"}
        rowsPerPageOptions={[31]}
        sortingMode={"server"}
        sortModel={sortModel}
        initialState={{
          sorting: {
            sortModel: [{ field: "noticeDate", sort: "desc" }],
          },
        }}
        onSortModelChange={onSortModelChange}
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: 0.17,
          },
        }}
      />
    </Paper>
  );
};

export interface CitationTableProps {
  page: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onNavigate: (id: string) => void;
  sortModel: GridSortModel;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

export default Citations;
