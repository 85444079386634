import { useQuery } from "@apollo/client";
import formatDate from "date-fns/format";
import isAfter from "date-fns/isAfter";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import LoadingSkeleton from "../LoadingSkeleton";
import Paper from "@mui/material/Paper";
import { GET_CITATION_BY_ID, GetCitationByIdData, GetCitationByIdVars } from "common/graphql/queries/GetCitation";
import times from "lodash/times";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  marginTop: -40,
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
}));

export const CitationStatusStepper: React.FC<CitationStatusStepperProps> = ({
  citationId,
  isCollectionsEnabled: isCollectionsEnabled = true,
}) => {
  const {
    data: citationData,
    loading: citationLoading,
    refetch: refetchCitation,
    error: citationError,
  } = useQuery<GetCitationByIdData, GetCitationByIdVars>(GET_CITATION_BY_ID, {
    variables: { id: citationId },
  });

  const refetchOnError = () => {
    if (citationError) {
      refetchCitation();
    }
  };

  const citation = citationData?.citation;

  if (citationError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const noticeDate = new Date(citation?.noticeSentAt || 0);
  const dueAt = new Date(citation?.dueAt || 0);
  const isPaid = !!citation?.paidAt;
  const paidAt = new Date(citation?.paidAt || 0);
  const sentToCollectionsAt = new Date(citation?.sentToCollectionsAt || 0);

  const isOverdue = isPaid ? isAfter(paidAt, dueAt) : isAfter(new Date(), dueAt);

  const steps = [
    {
      label: "Notice Date",
      date: formatDate(noticeDate, "MM/dd/yyyy"),
      checked: true,
      showDate: true,
    },
    {
      label: "Overdue",
      date: formatDate(dueAt, "MM/dd/yyyy"),
      checked: citation ? isOverdue : false,
      showDate: true,
    },
    {
      label: "Sent to Collections",
      date: formatDate(sentToCollectionsAt, "MM/dd/yyyy"),
      checked: !!citation?.sentToCollectionsAt,
      showDate: !!citation?.sentToCollectionsAt,
    },
  ];

  return (
    <Paper
      sx={{
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box mb={3} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={citationLoading} height={60}>
          <Typography variant="h5" fontWeight={400}>
            Citation Status
          </Typography>
        </LoadingSkeleton>
        {!citationLoading && (
          <Typography
            variant="h6"
            color={citation?.paidAt ? "#3A833C" : "error.main"}
            fontSize="1.25rem"
            fontWeight={400}
          >
            {citation?.paidAt ? `Paid on ${formatDate(new Date(citation?.paidAt || 0), "MM/dd/yyyy")}` : "Unpaid"}
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        {citationLoading ? (
          times(4, (index) => <Skeleton variant="circular" width={36} height={36} key={index} />)
        ) : (
          <Stepper connector={<CustomStepConnector />} sx={{ width: "100%" }}>
            {steps.slice(0, 3 - (isCollectionsEnabled ? 0 : 1)).map((step) => (
              <Step
                key={step.label}
                completed={step.checked}
                sx={{
                  ".MuiStepLabel-iconContainer": {
                    paddingRight: 0,
                  },
                }}
              >
                <StepLabel
                  sx={{ flexDirection: "column", textAlign: "center", ".MuiStepLabel-label": { lineHeight: 1 } }}
                >
                  <Typography variant="subtitle2" fontWeight={500} mt={1} lineHeight={1}>
                    {step.label}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ visibility: step.showDate ? "initial" : "hidden" }}
                    letterSpacing={0.4}
                    lineHeight={1}
                  >
                    {step.date}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Box>
    </Paper>
  );
};

export interface CitationStatusStepperProps {
  citationId: string;
  isCollectionsEnabled?: boolean; // TODO: Should be removed once we implemented SentCollections logic
}

export default CitationStatusStepper;
