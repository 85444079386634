import { useQuery } from "@apollo/client";
import {
  EventPolicyComplianceDetail,
  GET_CITATION_BY_ID,
  GetCitationByIdData,
  GetCitationByIdVars,
} from "common/graphql";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingSkeleton from "../LoadingSkeleton";
import { convertTimeToString, formatCurrency } from "common/format";
import { grey } from "@mui/material/colors";
import sumBy from "lodash/sumBy";
import flatMap from "lodash/flatMap";

export const CitationTransaction: React.FC<CitationTransactionProps> = ({ citationId }) => {
  const {
    data: citationData,
    loading: citationLoading,
    refetch: refetchCitation,
    error: citationError,
  } = useQuery<GetCitationByIdData, GetCitationByIdVars>(GET_CITATION_BY_ID, {
    variables: { id: citationId },
  });

  const refetchOnError = () => {
    if (citationError) {
      refetchCitation();
    }
  };

  const citation = citationData?.citation;

  if (citationError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const allDetails = flatMap(citation?.park?.policyRateDetails || [], "details") as EventPolicyComplianceDetail[];
  const violatedPark = allDetails.find((detail) => detail.rate === null && detail.price === null);

  const validEndTime = new Date(
    violatedPark
      ? violatedPark.rateStartAt.replace("+00:00", "")
      : citation?.sessionTransaction?.park?.endTimeLocal || 0,
  );
  const validParkAmount = sumBy(citation?.sessionTransaction?.park?.policyRateDetails || [], "price") || 0;

  return (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box px={3} pt={3} pb={1.5} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={citationLoading} height={60}>
          <Typography variant={"h5"} fontWeight={400}>
            Transaction ID: {citation?.sessionTransaction?.index.toString().padStart(6, "0")}
          </Typography>
        </LoadingSkeleton>
        {!!citation?.sessionTransaction && (
          <LoadingSkeleton loading={citationLoading} height={60}>
            <Typography variant="h6" color={"#3A833C"} fontSize="1.25rem" fontWeight={400}>
              Paid
            </Typography>
          </LoadingSkeleton>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1.25}
        pb={1}
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <Box>
          <LoadingSkeleton loading={citationLoading}>
            <Typography variant="subtitle1" fontWeight={600} letterSpacing={0.15}>
              Valid Park Time
            </Typography>
            <Typography variant="body1" letterSpacing={0.15}>
              {convertTimeToString(new Date(citation?.sessionTransaction?.park?.startTimeLocal || 0))} -{" "}
              {convertTimeToString(validEndTime)}
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={citationLoading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"} letterSpacing={0.15}>
              Amount
            </Typography>
            <Typography variant="body1" textAlign={"right"} letterSpacing={0.15}>
              ${formatCurrency(validParkAmount)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
    </Paper>
  );
};

export interface CitationTransactionProps {
  citationId: string;
}

export default CitationTransaction;
