import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import RealTimeOccupancyMap from "@app.automotus.io/components/RTO/RealTimeOccupancyMap";
import RealTimeOccupancyMapFilter, {
  RealTimeOccupancyMapLegendStatus,
} from "@app.automotus.io/components/RTO/RealTimeOccupancyMapFilter";
import RealTimeOccupancyMapLegend from "@app.automotus.io/components/RTO/RealTimeOccupancyMapLegend";
import RealTimeOccupancyPageHeader from "@app.automotus.io/components/RTO/RealTimeOccupancyPageHeader";
import RealTimeOccupancyMapFilterAlert from "@app.automotus.io/components/RTO/RealTimeOccupancyMapFilterAlert";
import RealTimeOccupancyOfflineZonesAlert from "@app.automotus.io/components/RTO/RealTimeOccupancyOfflineZonesAlert";
import RealTimeOccupancySidebar from "@app.automotus.io/components/RTO/RealTimeOccupancySidebar";
import RealTimeOccupancySideBarZoneSummary from "@app.automotus.io/components/RTO/RealTimeOccupancySideBarZoneSummary";
import RealTimeOccupancySidebarTabs from "@app.automotus.io/components/RTO/RealTimeOccupancySidebarTabs";
import RealTimeOccupancySidebarOverviewTabContents from "@app.automotus.io/components/RTO/RealTimeOccupancySidebarOverviewTabContents";
import RealTimeOccupancySideBarRealTimeTab from "@app.automotus.io/components/RTO/RealTimeOccupancySideBarRealTimeTabHeader";
import {
  CurbSpace,
  CurbsuiteGetRealTimeOccupancyData,
  GET_MAP_DATA,
  MapBounds,
  OnlineZone,
} from "common/graphql/queries";
import { RealTimeOccupancySpaceStatus } from "@app.automotus.io/components/RTO/RealTimeOccupancySpaceStatusIndicator";
import RealTimeOccupancyOfflineZonesSidebar from "@app.automotus.io/components/RTO/RealTimeOccupancyOfflineZonesSidebar";
import {
  GET_LANDING_PAGE_MAP_SETTING,
  GetLandingPageMapSettingData,
  GetLandingPageMapSettingVars,
} from "common/graphql";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";

const mapboxApiKey = process.env.REACT_APP_MAPBOX_API_KEY;

export const Operations: React.FC = () => {
  const { userProfile } = useUserProfile();
  const [defaultLocation, setDefaultLocation] = useState<{
    longitude: number;
    latitude: number;
  } | null>(null);
  const [mapBounds, setMapBounds] = useState<MapBounds | null>(null);
  // Fetch the landing page settings for the map
  const { data: mapSettingData, loading: mapSettingLoading } = useQuery<
    GetLandingPageMapSettingData,
    GetLandingPageMapSettingVars
  >(GET_LANDING_PAGE_MAP_SETTING, {
    variables: { payeeAccountId: userProfile?.account?.id || "" },
  });
  const { publishSnackbarMessage } = useSnackPackContext();

  const [fetchMapData, { loading: mapDataLoading, data, previousData }] = useLazyQuery<
    CurbsuiteGetRealTimeOccupancyData,
    MapBounds
  >(GET_MAP_DATA, {
    fetchPolicy: "no-cache",
    pollInterval: 30e3,
    notifyOnNetworkStatusChange: true,
  });

  const [zoneSelection, setZoneSelection] = useState<RealTimeOccupancySidebarSelection | null>(null);

  const [isOpenedLeftSidebar, setIsOpenedLeftSidebar] = useState(false);

  const [isOpenedOfflineZonesSidebar, setIsOpenedOfflineZonesSidebar] = useState(false);

  const [filterStatuses, setFilterStatuses] = useState<RealTimeOccupancyMapLegendStatus[]>([
    { available: true },
    { occupied: true },
    { occluded: true },
    { violation: true },
    { doubleparked: true },
  ]);

  useEffect(() => {
    if (mapSettingData && mapSettingData.mapSetting && mapSettingData.mapSetting.length > 0) {
      const { mapSetting } = mapSettingData;
      const { location_centerpoint } = mapSetting[0];
      setDefaultLocation({
        longitude: location_centerpoint.coordinates[0],
        latitude: location_centerpoint.coordinates[1],
      });
    }
  }, [mapSettingData]);

  // fetch map data and Set up polling for fetching
  useEffect(() => {
    if (mapBounds) {
      // Function to fetch map data with updated map bounds
      fetchMapData({
        variables: {
          minLong: mapBounds.minLong,
          maxLong: mapBounds.maxLong,
          minLat: mapBounds.minLat,
          maxLat: mapBounds.maxLat,
        },
      });
    }
  }, [mapBounds, fetchMapData]);

  const handleChangeZoneSelection = (newZoneSelection: RealTimeOccupancySidebarSelection | null) => {
    setZoneSelection(newZoneSelection);
    setIsOpenedLeftSidebar(!!newZoneSelection);
  };

  const mapData = mapDataLoading ? previousData : data;

  useEffect(() => {
    if (mapData) {
      setZoneSelection((prevSelection) => {
        if (!prevSelection) {
          return null;
        }

        const updatedZone = mapData.onlineZones.zones.find(({ id }) => id === prevSelection.zone.id);

        if (!updatedZone) {
          // When the currently selected zone goes offline, collapse the sidebar and notify the user of what has occurred.
          setIsOpenedLeftSidebar(false);
          publishSnackbarMessage({
            severity: "black",
            message: "Zone appears to be offline",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
          return null;
        }

        if (
          prevSelection.occupantId &&
          !updatedZone.occupants.some(({ vehicle }) => vehicle.id === prevSelection.occupantId)
        ) {
          // When the currently selected occupant leaves, collapse the sidebar and notify the user of what has occurred.
          handleChangeZoneSelection({ ...prevSelection, zone: updatedZone, occupantId: null });
          publishSnackbarMessage({
            severity: "black",
            message: "Selected vehicle is no longer visible",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          });
          return { ...prevSelection, zone: updatedZone, occupantId: null };
        }

        return { ...prevSelection, zone: updatedZone };
      });
    }
  }, [mapData, publishSnackbarMessage]);

  const handleResetFilter = () => {
    setFilterStatuses([
      { available: true },
      { occupied: true },
      { occluded: true },
      { violation: true },
      { doubleparked: true },
    ]);
  };

  const handleCopyZoneLocation = (): void => {
    if (!zoneSelection) {
      return;
    }

    // Copy google-maps friendly coordinates of the first point of the zone, flipping longitude and latitude to human-friendly
    // representation
    const [long, lat] = zoneSelection.zone.location.coordinates[0];
    navigator.clipboard.writeText(`${lat},${long}`);

    publishSnackbarMessage({ severity: "info", message: "Location copied to clipboard" });
  };

  const filteredZones = useMemo(() => {
    return filterStatuses.every((status) => !Object.values(status)[0])
      ? []
      : mapData?.onlineZones.zones.reduce((acc, onlineZone) => {
          const filteredOccupants = onlineZone.occupants.filter((occupant) =>
            filterStatuses.some((status) => status[occupant?.status]),
          );

          if (
            !(
              (onlineZone.occupants.length > 0 && filteredOccupants.length > 0) ||
              (onlineZone.occupants.length === 0 && filterStatuses.find((status) => status["available"]))
            )
          ) {
            return [...acc];
          }

          if (onlineZone.spaces === null) {
            return [...acc, { ...onlineZone, occupants: filteredOccupants }];
          }

          return [
            ...acc,
            {
              ...onlineZone,
              spaces:
                onlineZone.spaces &&
                onlineZone.spaces.reduce((acc, space) => {
                  if (space.isOccluded) {
                    if (filterStatuses.find((status) => !!status["occluded"])) {
                      return [...acc, space];
                    }
                    return [...acc];
                  }

                  if (filterStatuses.find((status) => space.occupant?.status && status[space.occupant?.status])) {
                    return [...acc, space];
                  }

                  if (!space?.occupant && filterStatuses.find((status) => status["available"])) {
                    return [
                      ...acc,
                      {
                        ...space,
                        occupant: null,
                      },
                    ];
                  }

                  return [...acc];
                }, [] as CurbSpace[]),
              occupants: filteredOccupants,
            },
          ];
        }, [] as OnlineZone[]);
  }, [mapData, filterStatuses]);

  const MemoizedMap = useMemo(() => {
    return defaultLocation ? (
      <RealTimeOccupancyMap
        mapboxApiKey={mapboxApiKey || ""}
        loading={mapSettingLoading}
        location={defaultLocation}
        onMarkerSelect={handleChangeZoneSelection}
        onMapBoundsChange={setMapBounds}
        zonesData={filteredZones || []}
      />
    ) : null;
  }, [defaultLocation, filteredZones, mapSettingLoading]);

  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        maxWidth: "1130px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: theme.spacing(3),
        margin: "0 auto",
        py: theme.spacing(6.25),
        boxSizing: "border-box",
      })}
    >
      <RealTimeOccupancyPageHeader accountId={userProfile?.account?.id} />
      <Paper
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          borderRadius: (theme) => theme.spacing(2),
          overflow: "hidden",
        }}
      >
        {filterStatuses?.some((filter) => !Object.values(filter)[0]) && (
          <RealTimeOccupancyMapFilterAlert onClickResetFilters={handleResetFilter} />
        )}
        {mapData && mapData.offlineZones.zones.length > 0 && (
          <RealTimeOccupancyOfflineZonesAlert
            numZonesOffline={mapData.offlineZones.zones.length}
            onClickViewZones={() => setIsOpenedOfflineZonesSidebar(true)}
          />
        )}
        <Box sx={{ width: "100%", height: "617px" }}>{MemoizedMap}</Box>
        <Box
          sx={(theme) => ({
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            py: theme.spacing(1),
            px: theme.spacing(3.5),
            gap: theme.spacing(3),
          })}
        >
          <RealTimeOccupancyMapLegend
            loading={mapSettingLoading}
            filterStatuses={
              filterStatuses
                ?.filter((item) => !!Object.values(item)[0])
                ?.map((item) => Object.keys(item)[0]) as RealTimeOccupancySpaceStatus[]
            }
          />
          <RealTimeOccupancyMapFilter
            loading={mapSettingLoading}
            filterStatuses={filterStatuses}
            onChangeFilterStatuses={(newFilterStatuses) => setFilterStatuses(newFilterStatuses)}
          />
        </Box>

        {isOpenedLeftSidebar && zoneSelection && (
          <RealTimeOccupancySidebarContainer
            isRefreshing={mapDataLoading}
            loading={mapSettingLoading}
            selection={zoneSelection}
            onChangeSelection={handleChangeZoneSelection}
            isOpened={isOpenedLeftSidebar}
            onClose={() => setIsOpenedLeftSidebar(false)}
            onCopyLocation={handleCopyZoneLocation}
          />
        )}
        {isOpenedOfflineZonesSidebar && (
          <RealTimeOccupancyOfflineZonesSidebar
            loading={mapSettingLoading}
            isRefreshing={mapDataLoading}
            zones={mapData?.offlineZones?.zones || []}
            isOpen={isOpenedOfflineZonesSidebar}
            onClose={() => setIsOpenedOfflineZonesSidebar(false)}
            contactUsHref={process.env.REACT_APP_CURBSUITE_ZENDESK_CONTACT_US_URL}
          />
        )}
      </Paper>
    </Box>
  );
};

export default Operations;

interface RealTimeOccupancySidebarContainerProps {
  isRefreshing: boolean;
  loading: boolean;
  onChangeSelection: (selection: RealTimeOccupancySidebarSelection | null) => void;
  selection: RealTimeOccupancySidebarSelection;
  isOpened: boolean;
  onCopyLocation: () => void;
  onClose: () => void;
}

interface RealTimeOccupancySidebarSelection {
  zone: OnlineZone;
  spaceIndex: number | null;
  occupantId: string | null;
}

const RealTimeOccupancySidebarContainer = ({
  isRefreshing,
  loading = false,
  isOpened,
  onChangeSelection,
  selection,
  onCopyLocation,
  onClose,
}: RealTimeOccupancySidebarContainerProps) => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <RealTimeOccupancySidebar isOpen={isOpened} onClose={onClose}>
      <Box display={"flex"} flexDirection={"column"} sx={{ height: "100%" }}>
        <RealTimeOccupancySideBarZoneSummary
          loading={loading}
          zoneImageSrc={selection?.zone.referenceImageUrl || ""}
          zoneAddress={selection?.zone.street_address}
          zoneLength={selection?.zone.lengthMeters}
          selectedSpaceIndex={selection?.spaceIndex}
          onCopyLocation={onCopyLocation}
        />

        <RealTimeOccupancySidebarTabs loading={loading} activeTab={activeTab} onTabSelect={setActiveTab} />
        {activeTab === 0 && (
          <RealTimeOccupancySidebarOverviewTabContents
            loading={loading}
            lastOccupiedAt={
              selection?.zone.usage?.lastOccupiedAt ? new Date(selection?.zone.usage?.lastOccupiedAt) : null
            }
            currentlyOccupied={(selection?.zone.occupants.length || 0) > 0}
            violationsToday={selection?.zone.usage?.numViolationsToday || 0}
            vehicleTypesDetectedToday={selection?.zone.usage?.vehicleTypesToday}
            utilizationToday={selection?.zone?.usage?.utilizationRatioToday}
          />
        )}
        {activeTab === 1 && selection && (
          <RealTimeOccupancySideBarRealTimeTab
            isSyncInProgress={isRefreshing}
            displayMode={selection.spaceIndex ? "space" : "zone"}
            selection={selection}
            onChangeSelection={onChangeSelection}
          />
        )}
      </Box>
    </RealTimeOccupancySidebar>
  );
};
