import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const TabsLoading: React.FC = () => (
  <Box>
    <Skeleton variant="rectangular" height={30} sx={{ mb: 2, mr: 5, ml: 5 }} />
  </Box>
);

export default TabsLoading;
