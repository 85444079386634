import { useQuery } from "@apollo/client";
import { GET_CITATION_BY_ID, GetCitationByIdData, GetCitationByIdVars } from "common/graphql";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingSkeleton from "../LoadingSkeleton";
import { convertTimeToString, formatCurrency } from "common/format";
import { grey } from "@mui/material/colors";
import { getCitationTimestamps } from "common/helpers";

export const ViolationPenalty: React.FC<ViolationPenaltyProps> = ({ citationId }) => {
  const {
    data: citationData,
    loading: citationLoading,
    refetch: refetchCitation,
    error: citationError,
  } = useQuery<GetCitationByIdData, GetCitationByIdVars>(GET_CITATION_BY_ID, {
    variables: { id: citationId },
  });

  const refetchOnError = () => {
    if (citationError) {
      refetchCitation();
    }
  };

  const citation = citationData?.citation;

  if (citationError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const { violationStartedAtLocal = new Date(0), violationEndedAtLocal = new Date(0) } = citation
    ? getCitationTimestamps(citation)
    : {};

  return (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box px={3} pt={3} pb={1.5} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={citationLoading} height={60}>
          <Typography variant={"h5"} fontWeight={400}>
            Violation Penalty
          </Typography>
        </LoadingSkeleton>
        {citation?.isSessionTransactionBillable && (
          <LoadingSkeleton loading={citationLoading} height={60}>
            <Typography
              variant="h6"
              color={citation?.paidAt ? "#3A833C" : "error.main"}
              fontSize="1.25rem"
              fontWeight={400}
            >
              {citation?.paidAt ? "Paid" : "Unpaid"}
            </Typography>
          </LoadingSkeleton>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1.25}
        pb={1}
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <Box>
          <LoadingSkeleton loading={citationLoading}>
            <Typography variant="subtitle1" fontWeight={600} letterSpacing={0.15}>
              Violation Time
            </Typography>
            <Typography variant="body1" letterSpacing={0.15}>
              {convertTimeToString(violationStartedAtLocal)} - {convertTimeToString(violationEndedAtLocal)}
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={citationLoading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"} letterSpacing={0.15}>
              Amount
            </Typography>
            <Typography variant="body1" textAlign={"right"} letterSpacing={0.15}>
              ${formatCurrency(citation?.totalAmountDue || 0)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
      {!citation?.isSessionTransactionBillable && (
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="row"
          px={3}
          py={2.5}
          sx={{
            backgroundColor: "rgba(13, 85, 191, 0.08)",
          }}
        >
          <Box>
            <LoadingSkeleton loading={citationLoading}>
              <Typography variant="h6" fontWeight={400}>
                Total Amount
              </Typography>
            </LoadingSkeleton>
          </Box>
          <Box>
            <LoadingSkeleton loading={citationLoading}>
              <Typography variant="h6" fontWeight={400}>
                ${formatCurrency(citation?.totalAmountDue || 0)}
              </Typography>
            </LoadingSkeleton>
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export interface ViolationPenaltyProps {
  citationId: string;
}

export default ViolationPenalty;
