import React from "react";
import times from "lodash/times";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";

export const TableLoadingOverlay: React.FC<TableLoadingOverlayProps> = ({
  numRows = 9,
  numColumns,
  rowHeight = 43,
}) => (
  <Grid container flexDirection="row" alignItems="center" sx={{ py: 3.5 }} rowSpacing={3.5} width={"100%"}>
    {times(numRows, (i) => (
      <Grid
        item
        container
        xs={12}
        columnSpacing={2}
        columns={numColumns}
        key={i}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        sx={{ px: 2 }}
      >
        {times(numColumns, (j) => (
          <Grid key={j} item xs={1}>
            <Skeleton variant="rectangular" width={"100%"} height={rowHeight} />
          </Grid>
        ))}
      </Grid>
    ))}
  </Grid>
);

export interface TableLoadingOverlayProps {
  numRows?: number;
  numColumns: number;
  rowHeight?: number;
}

export default TableLoadingOverlay;
