import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import formatDate from "date-fns/format";
import { SessionDetailsItem } from "@app.automotus.io/components/SessionDetailsItem";
import { useQuery } from "@apollo/client";
import { GET_TRANSACTION_DETAIL, GetTransactionDetailData, GetTransactionDetailVars } from "common/graphql";

export const InvoiceSessionDetails: React.FC<InvoiceSessionDetailsProps> = ({ transactionId }) => {
  const { data, loading } = useQuery<GetTransactionDetailData, GetTransactionDetailVars>(GET_TRANSACTION_DETAIL, {
    variables: {
      transactionId,
    },
  });

  const endTimeDate = data && new Date(data.transaction?.sessionEndedAtLocal || 0);
  const startTimeDate = data && new Date(data.transaction?.sessionStartedAtLocal || 0);
  const addressStreet = data?.transaction?.addressStreet;
  const addressCity = data?.transaction?.addressCity;
  const addressState = data?.transaction?.addressState;
  const licensePlateNumber = data?.transaction?.registeredVehicle?.licensePlate;
  const licensePlateStateCode = data?.transaction?.registeredVehicle?.stateCode;
  const isVehicleRegistered = data?.transaction?.registeredVehicle?.accounts?.some(
    ({ attachment_type }) => !attachment_type.includes("non_registration"),
  );

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Typography variant={"h5"} fontWeight={400}>
          Event Details
        </Typography>
        <Grid container>
          <SessionDetailsItem
            loading={loading}
            title={"Date"}
            content={endTimeDate && formatDate(endTimeDate, "M/d/yyyy")}
            skeletonWidth={104}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Time"}
            content={
              startTimeDate &&
              endTimeDate &&
              `${formatDate(startTimeDate, "hh:mm a")} - ${formatDate(endTimeDate, "hh:mm a")}`
            }
            skeletonWidth={166}
          />
          <SessionDetailsItem loading={loading} title={"Location"} content={addressStreet} skeletonWidth={111} />
          <SessionDetailsItem
            loading={loading}
            title={"City"}
            content={addressCity && addressState && `${addressCity}, ${addressState}`}
            skeletonWidth={112}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Vehicle"}
            content={licensePlateNumber && licensePlateStateCode && `${licensePlateStateCode} ${licensePlateNumber}`}
            skeletonWidth={104}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Vehicle Status"}
            content={isVehicleRegistered ? "Registered" : "Unregistered"}
            skeletonWidth={166}
          />
        </Grid>
      </Stack>
    </Paper>
  );
};

export interface InvoiceSessionDetailsProps {
  transactionId: string;
}

export default InvoiceSessionDetails;
