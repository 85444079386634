import React from "react";
import { DataGridPro, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { PayeeTableToolbar } from "../PayeeTableToolbar/PayeeTableToolbar";
import { TableErrorOverlay } from "../TableErrorOverlay/TableErrorOverlay";
import {
  DAILY_TRANSACTION_AMOUNT_COLUMN,
  DAILY_TRANSACTION_ID_COLUMN,
  MONTHLY_DATE_COLUMN,
  MONTHLY_TOTAL_AMOUNT_COLUMN,
} from "@app.automotus.io/components/PayeeTables/common";
import formatDate from "date-fns/format";
import TableLoadingOverlay from "@app.automotus.io/components/PayeeTables/TableLoadingOverlay";
import { CustomPagination } from "@app.automotus.io/components/CustomPagination";

const DEFAULT_PAGE_SIZE = 31;

/**
 * Displays park transactions in tabular format for a payee user. Most stateful interactions must be handled
 * a component higher in the tree.
 */
export const PayeeTransactionsTable: React.FC<PayeeTransactionsTableProps> = ({
  loading = false,
  rows,
  displayMode,
  onClickDateRow = () => undefined,
  onClickTransactionRow = () => undefined,
  selectedDate,
  onChangeSelectedDate = () => undefined,
  maxDate,
  onClickTryAgain = () => undefined,
  error,
  page,
  onPageChange,
  sortModel,
  onSortModelChange,
}) => {
  const columns = displayMode === "month" ? MONTHLY_COLUMNS : DAILY_COLUMNS;

  const exportFileName = `session_transactions_${formatDate(
    selectedDate,
    displayMode === "month" ? "yyyy-MM" : "yyyy-MM-dd",
  )}`;

  return (
    <div style={{ height: 876, maxWidth: "100%" }}>
      <DataGridPro
        components={{
          Toolbar: PayeeTableToolbar,
          ErrorOverlay: TableErrorOverlay,
          LoadingOverlay: TableLoadingOverlay,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            selectedDate,
            onChangeSelectedDate,
            displayMode,
            maxDate,
            exportFileName,
          },
          errorOverlay: {
            onTryAgain: onClickTryAgain,
          },
          loadingOverlay: {
            numColumns: columns.length,
          },
        }}
        loading={loading}
        getRowId={(row) => (displayMode === "month" ? row.date.getTime() : row.transactionId)}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        error={error}
        pagination
        pageSize={DEFAULT_PAGE_SIZE}
        page={page}
        onPageChange={onPageChange}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
        columns={columns}
        rows={rows}
        onRowClick={displayMode === "month" ? onClickDateRow : onClickTransactionRow}
        sx={{
          "& .MuiDataGrid-cell:hover": {
            cursor: "pointer",
          },
        }}
      />
    </div>
  );
};

const MONTHLY_COLUMNS: GridColDef[] = [MONTHLY_DATE_COLUMN, MONTHLY_TOTAL_AMOUNT_COLUMN(true)];

const PARK_PERIOD_FORMAT = "hh:mm aa";

const DAILY_COLUMNS: GridColDef[] = [
  DAILY_TRANSACTION_ID_COLUMN,
  {
    field: "timeRange",
    headerName: "Park Period",
    sortComparator: (v1, v2) => v1.end.getTime() - v2.end.getTime(),
    flex: 1,
    align: "left",
    headerAlign: "left",
    sortingOrder: ["desc", "asc"],
    valueFormatter: (params) =>
      `${formatDate(params.value.start, PARK_PERIOD_FORMAT)} - ${formatDate(params.value.end, PARK_PERIOD_FORMAT)}`,
  },
  {
    field: "addressStreet",
    headerName: "Address",
    align: "left",
    headerAlign: "left",
    type: "string",
    sortable: false,
  },
  DAILY_TRANSACTION_AMOUNT_COLUMN(true),
];

/** Row of monthly data */
export interface PayeeMonthlyTransactionsTableRow {
  /** Date corresponding to the row */
  date: Date;
  /**
   * Sum total amount of transactions that occurred on the given date, expressed in terms of the smallest denomination
   * of the currency that transactions are expressed in.
   */
  amount: number;
}

/** Row of daily data */
export interface PayeeDailyTransactionsTableRow {
  /** Index of the transaction record */
  transactionIndex: number;
  /** ID of the transaction record */
  transactionId: string;
  /** Time range of the transaction */
  timeRange: {
    /** Start time of the transaction */
    start: Date;
    /** End time of the transaction */
    end: Date;
  };
  /** Street address of the transaction */
  addressStreet: string;
  /** Amount transacted, expressed in terms of the smallest denomination of the currency that the transaction is expressed in */
  amount: number;
}

/** Props passed to initialize a {@link PayeeTransactionsTable} */
export interface PayeeTransactionsTableProps {
  /** Indicates that table data is loading */
  loading?: boolean;
  /** Click handler fired when a row of monthly data is clicked */
  onClickDateRow?: (params: GridRowParams<PayeeMonthlyTransactionsTableRow>) => void;
  /** Click handler fired when a single transaction row is clicked */
  onClickTransactionRow?: (params: GridRowParams<PayeeDailyTransactionsTableRow>) => void;
  /**
   * Currently selected date. When displayMode is "month," should be the first of the month. Otherwise will be the
   * date of transactions
   */
  selectedDate: Date;
  /** Handler fired when the selected date is changed */
  onChangeSelectedDate?: (d: Date) => void;
  /** Maximum date that can be selected */
  maxDate: Date;
  /** Indicates whether an error occurred fetching data */
  error?: Error;
  /** Handler fired when a user requests to try again after table data fails to load */
  onClickTryAgain?: () => void;
  /** Display mode */
  displayMode: "month" | "day";
  /** Rows of data */
  rows: PayeeMonthlyTransactionsTableRow[] | PayeeDailyTransactionsTableRow[];
  /** page */
  page: number;
  /** page change handler */
  onPageChange: (page: number) => void;
  /** sort model */
  sortModel: GridSortModel;
  /** sort model change handler */
  onSortModelChange: (sortModel: GridSortModel) => void;
}

export default PayeeTransactionsTable;
