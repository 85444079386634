import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { formatDateAgo } from "common/format/time";
import React from "react";
import { styled, alpha } from "@mui/material/styles";
import _times from "lodash/times";

const BoxRow = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.23)}`,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const formatUtilization = (utilizationToday: number) => {
  return `${Math.ceil(utilizationToday * 100)}%`;
};

export const RealTimeOccupancySidebarOverviewTabContents: React.FC<RealTimeOccupancySidebarOverviewTabContentsProps> =
  ({ loading, lastOccupiedAt, currentlyOccupied, violationsToday, utilizationToday, vehicleTypesDetectedToday }) => {
    return (
      <>
        <BoxRow>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1" py={0.5}>
              Last Occupied
            </Typography>
          </LoadingSkeleton>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1">
              {currentlyOccupied ? "now" : lastOccupiedAt ? formatDateAgo(lastOccupiedAt) : "-"}
            </Typography>
          </LoadingSkeleton>
        </BoxRow>
        <BoxRow>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1" py={0.5}>
              Violations Today
            </Typography>
          </LoadingSkeleton>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1">{violationsToday}</Typography>
          </LoadingSkeleton>
        </BoxRow>
        <BoxRow>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1" py={0.5}>
              Utilization Today
            </Typography>
          </LoadingSkeleton>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1">{formatUtilization(utilizationToday || 0)}</Typography>
          </LoadingSkeleton>
        </BoxRow>
        <BoxRow sx={{ borderBottom: "none !important" }}>
          <LoadingSkeleton width="100%" loading={loading}>
            <Typography variant="body1" py={0.5}>
              Vehicle Types Detected Today
            </Typography>
          </LoadingSkeleton>
        </BoxRow>
        {loading ? (
          <Box width={"100%"} px={2} display={"flex"} flexWrap={"wrap"} gap={1}>
            {_times(3, (index) => (
              <LoadingSkeleton width="100%" loading={loading}>
                <Chip key={index} variant="filled" label={""} size={"medium"} sx={{ width: 60 }} />
              </LoadingSkeleton>
            ))}
          </Box>
        ) : (
          <Box width={"100%"} px={2} display={"flex"} flexWrap={"wrap"} gap={1}>
            {vehicleTypesDetectedToday &&
              vehicleTypesDetectedToday.map((vehicleType) => (
                <Chip key={vehicleType} variant="filled" label={vehicleType} size={"medium"} />
              ))}
          </Box>
        )}
      </>
    );
  };

export interface RealTimeOccupancySidebarOverviewTabContentsProps {
  loading: boolean;
  lastOccupiedAt?: Date | null;
  currentlyOccupied?: boolean;
  violationsToday?: number;
  utilizationToday?: number;
  vehicleTypesDetectedToday?: string[];
}

export default RealTimeOccupancySidebarOverviewTabContents;
