import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import LoadingSkeleton from "../LoadingSkeleton";
import { DataGridPro, GridColumns } from "@mui/x-data-grid-pro";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { convertTimeToString, formatCurrency, formatSessionDateRange } from "common/format";
import _times from "lodash/times";
import sumBy from "lodash/sumBy";
import { CustomPagination } from "../CustomPagination";

export const InvoiceParkEvents: React.FC<InvoiceParkEventsProps> = ({ invoiceId, onClickParkEvent }) => {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  const invoice = invoiceData?.invoice;

  if (invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const columns: GridColumns = [
    {
      field: "parkPeriod",
      headerName: "Park Period",
      sortable: true,
      width: 243,
      renderCell: (params) => (
        <Box>
          <Typography variant="body1">{formatSessionDateRange(params.row.startTime, params.row.endTime)}</Typography>
          <Typography variant="body4">
            {convertTimeToString(params.row.startTime)} - {convertTimeToString(params.row.endTime)}
          </Typography>
        </Box>
      ),
      valueGetter: (params) => {
        return {
          date: params.row.date,
          startTime: params.row.startTime,
          endTime: params.row.endTime,
        };
      },
      sortComparator: (v1, v2) => {
        if (v1.date.getTime() < v2.date.getTime()) return -1;
        if (v1.date.getTime() > v2.date.getTime()) return 1;
        if (v1.startTime.getTime() < v2.startTime.getTime()) return -1;
        if (v1.startTime.getTime() > v2.startTime.getTime()) return 1;
        if (v1.endTime.getTime() < v2.endTime.getTime()) return -1;
        if (v1.endTime.getTime() > v2.endTime.getTime()) return 1;
        return 0;
      },
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      width: 400,
      valueGetter: (params) => params.row.address,
    },
    {
      field: "amount",
      headerName: "Amount",
      sortable: false,
      width: 196,
      renderCell: (params) => <Typography variant="body1">${formatCurrency(params.row.amount || 0)}</Typography>,
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{ cursor: "pointer", width: "100%", display: "flex", justifyContent: "center" }}
          onClick={() => onClickParkEvent(params.row.id)}
        >
          <ArrowForwardIosIcon sx={{ color: (theme) => theme.palette.action.active }} />
        </Box>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        pt: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box mb={3} ml={3} mr={3}>
        <LoadingSkeleton loading={invoiceLoading} height={60}>
          <Typography variant="h5" fontWeight={400}>
            Park Events
          </Typography>
        </LoadingSkeleton>
      </Box>
      <Box sx={{ height: 400, width: "100%", display: "flex", px: invoiceLoading ? 3 : 0 }}>
        {!invoiceLoading && (
          <DataGridPro
            columns={columns}
            rows={
              invoice?.transactions.map((transaction) => ({
                id: transaction.id,
                address: transaction?.address_street || "",
                amount: sumBy(transaction.park.policyRateDetails || [], "price"),
                date: new Date(transaction.sessionStartedAtLocal || 0),
                startTime: new Date(transaction.sessionStartedAtLocal || 0),
                endTime: new Date(transaction.sessionEndedAtLocal || 0),
              })) || []
            }
            pagination
            pageSize={10}
            rowsPerPageOptions={[10, 30, 50]}
            rowHeight={72}
            loading={invoiceLoading}
            disableSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <Typography variant="h6" m={1.5}>
                  There are currently no transactions to report
                </Typography>
              ),
              Pagination: CustomPagination,
            }}
            sx={{
              border: "none",
              ".MuiDataGrid-columnHeaders": {
                pl: 4,
              },
              ".MuiDataGrid-cell": {
                border: "none",
                px: 2,
              },
              ".MuiDataGrid-row": {
                minHeight: 0,
                maxHeight: 0,
                pl: 4,
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              ".MuiDataGrid-columnHeaderTitle": {
                fontSize: "18px",
                fontWeight: 700,
                lineHeight: "24px",
                letterSpacing: 0.17,
              },
              ".MuiDataGrid-columnHeader": {
                p: 2,
              },
            }}
          />
        )}
        {invoiceLoading &&
          _times(3, (index) => (
            <Box key={index} sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Skeleton width={80} height={60} />
              <Skeleton width={120} height={60} />
              <Skeleton width={120} height={60} />
              <Skeleton width={120} height={60} />
              <Skeleton width={120} height={60} />
            </Box>
          ))}
      </Box>
    </Paper>
  );
};

export interface InvoiceParkEventsProps {
  invoiceId: string;
  onClickParkEvent: (transactionId: string) => void;
}

export default InvoiceParkEvents;
