import React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import formatDate from "date-fns/format";
import { SessionDetailsItem } from "@app.automotus.io/components/SessionDetailsItem";
import { useQuery } from "@apollo/client";
import { GET_CITATION_BY_ID, GetCitationByIdData, GetCitationByIdVars } from "common/graphql";
import { getViolationTypeLabel } from "@curbsuite/routes/dashboard/citations/utils";
import LocalLevelError from "../common/LocalLevelError";
import { convertTimeToString } from "common/format";
import { getCitationTimestamps } from "common/helpers";

export const CitationDetails: React.FC<CitationDetailsProps> = ({ citationId }) => {
  const { data, loading, error, refetch } = useQuery<GetCitationByIdData, GetCitationByIdVars>(GET_CITATION_BY_ID, {
    variables: { id: citationId },
    onError(err) {
      console.error(`failed to fetch citation by id ${citationId}`, err);
    },
  });

  if (error) {
    return (
      <Paper sx={{ p: 3 }}>
        <LocalLevelError onTryAgain={refetch} />
      </Paper>
    );
  }

  const citation = data?.citation;

  const addressStreet = citation?.sessionTransaction?.addressStreet;
  const addressCity = citation?.sessionTransaction?.addressCity;
  const addressState = citation?.sessionTransaction?.addressState;
  const licensePlateNumber = citation?.sessionTransaction?.registeredVehicle?.licensePlate;
  const licensePlateStateCode = citation?.sessionTransaction?.registeredVehicle?.stateCode;
  const hasTransaction = citation?.isSessionTransactionBillable;
  const violationType = citation?.violationType;
  const { violationStartedAtLocal = new Date(0), violationEndedAtLocal = new Date(0) } = citation
    ? getCitationTimestamps(citation)
    : {};

  return (
    <Paper sx={{ p: 3 }}>
      <Stack spacing={1}>
        <Typography variant={"h5"} fontWeight={400}>
          Citation Details
        </Typography>
        <Grid container rowSpacing={2}>
          <SessionDetailsItem
            loading={loading}
            title={"Violation Date"}
            content={formatDate(violationStartedAtLocal, "M/d/yyyy")}
            skeletonWidth={104}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Violation Time"}
            content={`${convertTimeToString(violationStartedAtLocal)} - ${convertTimeToString(violationEndedAtLocal)}`}
            skeletonWidth={166}
          />
          <SessionDetailsItem loading={loading} title={"Location"} content={addressStreet} skeletonWidth={111} />
          <SessionDetailsItem
            loading={loading}
            title={"City"}
            content={addressCity && addressState && `${addressCity}, ${addressState}`}
            skeletonWidth={112}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Vehicle"}
            content={licensePlateNumber && licensePlateStateCode && `${licensePlateStateCode} ${licensePlateNumber}`}
            skeletonWidth={104}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Has Transaction"}
            content={hasTransaction ? "Yes" : "No"}
            skeletonWidth={166}
          />
          <SessionDetailsItem
            loading={loading}
            title={"Violation Type"}
            content={getViolationTypeLabel(violationType || "")}
            skeletonWidth={112}
          />
        </Grid>
      </Stack>
    </Paper>
  );
};

export interface CitationDetailsProps {
  citationId: string;
}

export default CitationDetails;
