import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Error404 from "@app.automotus.io/components/scenes/Errors/Error404";
import Authorize from "@app.automotus.io/components/routes/authorize";
import SignUp from "@app.automotus.io/components/routes/signup";
import SignUpProfile from "@app.automotus.io/components/routes/signup/profile";
import SignUpTermsOfService from "@app.automotus.io/components/routes/signup/terms-of-service";
import SignUpVehicle from "@app.automotus.io/components/routes/signup/vehicle";
import SignUpCurbPass from "@app.automotus.io/components/routes/signup/curbpass";
import { MainLayout } from "@curbsuite/routes/layout/MainLayout";
import { ProtectedComponent } from "@app.automotus.io/components/auth";
import { SignUpFinish } from "@app.automotus.io/components/routes/signup/finish";
import { Account } from "@app.automotus.io/components/routes/account";
import withAuthorizedRedirection from "@app.automotus.io/components/auth/withAuthorizedRedirection/withAuthorizedRedirection";
import ReactGA from "react-ga4";
import { ErrorBoundary } from "react-error-boundary";
import PageLevelError from "@app.automotus.io/components/common/PageLevelError";
import { DisputeTransaction } from "@app.automotus.io/components/routes/transactions/transaction/dispute";
import { TransactionLayout } from "@app.automotus.io/components/routes/transactions/transaction/transaction-layout";
import { Transaction } from "@app.automotus.io/components/routes/transactions/transaction/transaction";
import startCase from "lodash/startCase";
import { Logout } from "@app.automotus.io/components/routes/logout";
import { VerifyCrossOrigin } from "@app.automotus.io/components/routes/verify-cross-origin";
import Analytics, { TableauView } from "@app.automotus.io/components/routes/analytics";
import Operations from "@app.automotus.io/components/routes/operations";
import CurbsuiteLogin from "@curbsuite/routes/login";
import { DashboardLayout } from "@curbsuite/routes/dashboard/layout";
import { Dashboard } from "@curbsuite/routes/dashboard";
import Invoice from "@curbsuite/routes/dashboard/invoices/invoice";
import InvoiceTransaction from "@curbsuite/routes/dashboard/invoices/transactions/transaction";
import Invoices from "@curbsuite/routes/dashboard/invoices";
import Citation from "@curbsuite/routes/dashboard/citations/citation";
import Citations from "@curbsuite/routes/dashboard/citations";

function usePageViews() {
  const location = useLocation();

  useEffect(() => {
    const paths = location.pathname.split("/");

    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title:
        location.pathname === "/"
          ? "Home"
          : `${paths
              .slice(1)
              .map((pathElement) => startCase(pathElement))
              .join(" - ")}`,
    });
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

export const AppRoutes: React.FC = () => {
  usePageViews();

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/verify-cross-origin" element={<VerifyCrossOrigin />} />
      <Route path={"/logout"} element={<Logout />} />
      <Route path="/authorize" element={<Authorize />} />
      <Route path="/login" element={<CurbsuiteLogin />} />
      <Route
        path="/terms-of-service"
        element={
          <Box sx={{ p: 4 }}>
            <SignUpTermsOfService />
          </Box>
        }
      />
      <Route path="/signup" element={<ProtectedComponent component={SignUp} />}>
        <Route path="profile" element={<SignUpProfile />} />
        <Route path="payment" element={<SignUpCurbPass />} />
        <Route path="terms-of-service" element={<SignUpTermsOfService />} />
        <Route path="vehicle" element={<SignUpVehicle />} />
        <Route path="finish" element={<SignUpFinish />} />
      </Route>
      <Route
        element={
          <ErrorBoundary
            FallbackComponent={PageLevelError}
            onReset={() => {
              window.location.reload();
            }}
          >
            <ProtectedComponent component={withAuthorizedRedirection(MainLayout)} />
          </ErrorBoundary>
        }
      >
        <Route path="/account" element={<Account />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path={"invoices"}>
            <Route path={":invoiceId"} element={<Invoice />} />
            <Route path={":invoiceId/transactions/:transactionId"} element={<InvoiceTransaction />} />
            <Route index element={<Invoices />} />
          </Route>
          <Route path={"citations"}>
            <Route path={":citationId"} element={<Citation />} />
            <Route index element={<Citations />} />
          </Route>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="/analytics" element={<Analytics />}>
          <Route path=":view" element={<TableauView />} />
        </Route>
        <Route path="/operations" element={<Operations />} />
        <Route path="/transactions/:transactionId" element={<TransactionLayout />}>
          <Route path="dispute" element={<DisputeTransaction />} />
          <Route index element={<Transaction />} />
        </Route>
      </Route>
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default AppRoutes;
