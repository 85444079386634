import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export const YearSelect: React.FC<YearSelectProps> = ({
  year,
  minYear = 0,
  maxYear = Infinity,
  onChange = () => undefined,
}) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <IconButton
        aria-label={"previous-year"}
        sx={{ visibility: year === minYear ? "hidden" : undefined }}
        disabled={year === minYear}
        onClick={() => {
          year && onChange(year - 1);
        }}
      >
        <ArrowLeftIcon />
      </IconButton>
      <Box sx={{ borderRadius: 2, border: 2, borderColor: (theme) => theme.palette.grey[400], px: 0.75, py: 0.25 }}>
        <Typography>{year || ""}</Typography>
      </Box>
      <IconButton
        aria-label={"next-year"}
        sx={{ visibility: year && year >= maxYear ? "hidden" : undefined }}
        disabled={year === maxYear}
        onClick={() => {
          year && onChange(year + 1);
        }}
      >
        <ArrowRightIcon />
      </IconButton>
    </Box>
  );
};

export interface YearSelectProps {
  year?: number | null;
  minYear?: number;
  maxYear?: number;
  onChange?: (year: number) => void;
}

export default YearSelect;
