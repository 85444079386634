import React from "react";
import Box from "@mui/material/Box";

const statusColorMap = {
  available: "#9E9E9E",
  occupied: "#3A833C",
  occluded: "transparent",
  violation: "#E31B0C",
  doubleparked: "transparent",
  zoneSelected: "transparent",
  selected: "transparent",
};

const statusBorderColorMap = {
  available: "#9E9E9E",
  occupied: "#3A833C",
  occluded: "#3A833C",
  violation: "#E31B0C",
  doubleparked: "#E31B0C",
  zoneSelected: "transparent",
  selected: "#0D55BF",
};

const statusBorderWidthMap = {
  available: "0px",
  occupied: "0px",
  occluded: "1.5px",
  violation: "0px",
  doubleparked: "1.5px",
  zoneSelected: "transparent",
  selected: "3px",
};

export const RealTimeOccupancySpaceStatusIndicator: React.FC<RealTimeOccupancySpaceStatusIndicatorProps> = ({
  status,
}) => {
  return (
    <Box
      sx={(theme) => ({
        width: theme.spacing(1.5),
        height: theme.spacing(1.5),
        borderRadius: "50%",
        borderWidth: statusBorderWidthMap[status],
        borderStyle: "solid",
        borderColor: statusBorderColorMap[status],
        backgroundColor: statusColorMap[status],
        boxSizing: "border-box",
      })}
    />
  );
};

export type RealTimeOccupancySpaceStatus =
  | "available"
  | "occupied"
  | "occluded"
  | "violation"
  | "doubleparked"
  | "zoneSelected"
  | "selected";

export interface RealTimeOccupancySpaceStatusIndicatorProps {
  status: RealTimeOccupancySpaceStatus;
}

export default RealTimeOccupancySpaceStatusIndicator;
