import React from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { NavBar } from "@app.automotus.io/components/NavBar";
import Box from "@mui/material/Box";
import { useLogout } from "@app.automotus.io/components/auth";
import { useUserProfile } from "@app.automotus.io/components/hooks";

export const MainLayout: React.FC = () => {
  const mainRef = React.useRef(null);
  const logout = useLogout();
  const { userProfile } = useUserProfile();

  const NAV_LINKS = [
    {
      title: "Transactions",
      to:
        userProfile?.account?.id !== process.env.REACT_APP_PIKEVILLE_ACCOUNT_ID ? "/dashboard" : "/dashboard/citations",
    },
    { title: "Operations", to: "/operations" },
    {
      title: "Analytics",
      to: "/analytics",
    },
    {
      title: "Support",
      to: process.env.REACT_APP_CURBSUITE_ZENDESK_SUPPORT_TICKET_URL || "",
      linkType: "external",
    },
    {
      title: "Account",
      to: "/account",
    },
    {
      title: "Logout",
      to: "/",
      onClick: async (e: React.SyntheticEvent) => {
        e.preventDefault();

        await logout();
      },
    },
  ];

  return (
    <>
      <CssBaseline />
      <NavBar targets={NAV_LINKS} />
      <Box component="main" ref={mainRef} sx={{ pt: (theme) => theme.spacing(11) }}>
        <Outlet />
      </Box>
    </>
  );
};
