import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React, { useState, useEffect } from "react";
import ImageIcon from "@mui/icons-material/Image";

export const ImageLoad: React.FC<ImageLoadProps> = ({ width, height, imageUrl, alt }) => {
  const [imageLoading, setImageLoading] = useState(true);
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  useEffect(() => {
    // Any time the image URL changes, reset the loading state
    setImageLoadFailed(false);
    setImageLoading(true);
  }, [imageUrl]);

  const onImageError = () => {
    setImageLoadFailed(true);
    setImageLoading(false);
  };

  const onImageLoad = () => {
    setImageLoadFailed(false);
    setImageLoading(false);
  };

  const imageLoaded = !imageLoading && !imageLoadFailed;

  // Display placeholder if image fails to load or if no image is available.
  const displayPlaceholder = !imageUrl || !imageLoaded;

  return (
    <Box width={width} height={height} position={"relative"}>
      {imageLoading && <Skeleton width="100%" height="100%" sx={{ transform: "none" }} />}
      {displayPlaceholder && (
        <ImageIcon
          sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, m: "auto", color: "action.active" }}
        />
      )}
      <img
        style={!imageLoaded ? { display: "none" } : {}}
        src={imageUrl}
        width={width}
        height={height}
        alt={alt}
        onLoad={onImageLoad}
        onError={onImageError}
      />
    </Box>
  );
};

export interface ImageLoadProps {
  width: string | number;
  height: string | number;
  imageUrl: string;
  alt: string;
}

export default ImageLoad;
