import React from "react";
import { DataGridPro, GridColDef, GridRowParams, GridSortModel } from "@mui/x-data-grid-pro";
import { PayeeTableToolbar } from "../PayeeTableToolbar/PayeeTableToolbar";
import { TableErrorOverlay } from "../TableErrorOverlay/TableErrorOverlay";
import {
  DAILY_TRANSACTION_AMOUNT_COLUMN,
  DAILY_TRANSACTION_ID_COLUMN,
  MONTHLY_DATE_COLUMN,
  MONTHLY_TOTAL_AMOUNT_COLUMN,
} from "@app.automotus.io/components/PayeeTables/common";
import TableLoadingOverlay from "@app.automotus.io/components/PayeeTables/TableLoadingOverlay";
import formatDate from "date-fns/format";
import { CustomPagination } from "@app.automotus.io/components/CustomPagination";

/**
 * Displays digital wallet transactions in tabular format for a payee user. Most stateful interactions must be handled
 * a component higher in the tree.
 */
const DEFAULT_PAGE_SIZE = 31;

export const PayeeWalletTransactionsTable: React.FC<PayeeWalletTransactionsTableProps> = ({
  loading = false,
  rows,
  displayMode,
  onClickDateRow = () => undefined,
  selectedDate,
  onChangeSelectedDate = () => undefined,
  maxDate,
  onClickTryAgain = () => undefined,
  error,
  page,
  onPageChange,
  sortModel,
  onSortModelChange,
}) => {
  const columns = displayMode === "month" ? MONTHLY_COLUMNS : DAILY_COLUMNS;

  const exportFileName = `wallet_transactions_${formatDate(
    selectedDate,
    displayMode === "month" ? "yyyy-MM" : "yyyy-MM-dd",
  )}`;

  return (
    <div style={{ height: 876, maxWidth: "100%" }}>
      <DataGridPro
        components={{
          Toolbar: PayeeTableToolbar,
          ErrorOverlay: TableErrorOverlay,
          LoadingOverlay: TableLoadingOverlay,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            selectedDate,
            onChangeSelectedDate,
            displayMode,
            maxDate,
            exportFileName,
          },
          errorOverlay: {
            onTryAgain: onClickTryAgain,
          },
          loadingOverlay: {
            numColumns: columns.length,
          },
        }}
        loading={loading}
        getRowId={(row) => (displayMode === "month" ? row.date.getTime() : row.transactionId)}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        logger={{ ...console, debug: console.log }}
        error={error}
        pagination
        page={page}
        onPageChange={onPageChange}
        initialState={{
          sorting: {
            sortModel: sortModel,
          },
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        pageSize={DEFAULT_PAGE_SIZE}
        rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
        columns={columns}
        rows={rows}
        onRowClick={displayMode === "month" ? onClickDateRow : undefined}
        sx={
          displayMode === "month"
            ? {
                "& .MuiDataGrid-cell:hover": {
                  cursor: "pointer",
                },
              }
            : undefined
        }
      />
    </div>
  );
};

const MONTHLY_COLUMNS: GridColDef[] = [MONTHLY_DATE_COLUMN, MONTHLY_TOTAL_AMOUNT_COLUMN(true)];

const DAILY_COLUMNS: GridColDef[] = [
  DAILY_TRANSACTION_ID_COLUMN,
  {
    field: "transactionType",
    headerName: "Transaction Type",
    type: "string",
    flex: 1,
    valueFormatter: (params) => {
      switch (params.value) {
        case "wallet_reload":
          return "Wallet Reload";
        case "initial_wallet_load":
          return "New Account";
        case "wallet_refund":
          return "Wallet Refund";
        default:
          return "Unknown";
      }
    },
    sortable: false,
  },
  DAILY_TRANSACTION_AMOUNT_COLUMN(),
];

/** Row of monthly data */
export interface PayeeMonthlyWalletTransactionsRow {
  /** Date corresponding to the row */
  date: Date;
  /**
   * Sum total amount of transactions that occurred on the given date, expressed in terms of the smallest denomination
   * of the currency that transactions are expressed in.
   */
  amount: number;
}

/** Row of daily data */
export interface PayeeDailyWalletTransactionsRow {
  /** ID of the transaction record */
  transactionId: string;
  /** Type of transaction */
  transactionType: "wallet_reload" | "initial_wallet_load" | "payout" | "wallet_refund";
  /** Amount transacted, expressed in terms of the smallest denomination of the currency that the transaction is expressed in */
  amount: number;
}

/** Props passed to initialize a {@link PayeeWalletTransactionsTable} */
export interface PayeeWalletTransactionsTableProps {
  /** Indicates that table data is loading */
  loading?: boolean;
  /** Click handler fired when a row of monthly data is clicked */
  onClickDateRow?: (params: GridRowParams<PayeeMonthlyWalletTransactionsRow>) => void;
  /**
   * Currently selected date. When displayMode is "month," should be the first of the month. Otherwise will be the
   * date of transactions
   */
  selectedDate: Date;
  /** Handler fired when the selected date is changed */
  onChangeSelectedDate?: (d: Date) => void;
  /** Maximum date that can be selected */
  maxDate: Date;
  /** Indicates whether an error occurred fetching data */
  error?: Error;
  /** Handler fired when a user requests to try again after table data fails to load */
  onClickTryAgain?: () => void;
  /** Display mode */
  displayMode: "month" | "day";
  /** Rows of data */
  rows: PayeeMonthlyWalletTransactionsRow[] | PayeeDailyWalletTransactionsRow[];
  /** page */
  page: number;
  /** page change handler */
  onPageChange: (page: number) => void;
  /** sort model */
  sortModel: GridSortModel;
  /** sort model change handler */
  onSortModelChange: (sortModel: GridSortModel) => void;
}

export default PayeeWalletTransactionsTable;
