import React from "react";
import Dialog from "@mui/material/Dialog";
import { formatVehicleType, formatVehicleColor } from "common/format/occupant";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { VehicleInformationBoxRow } from "../RealTimeOccupancySideBarSpaceOccupant";
import ImageLoad from "@app.automotus.io/components/ImageLoad";
import { Occupant } from "common/graphql/queries";

export const RealTimeOccupancyImageViewerDialog: React.FC<RealTimeOccupancyImageViewerDialogProps> = ({
  open,
  onClose,
  occupant,
}) => {
  const { session, vehicle } = occupant;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "90vw",
          maxWidth: { lg: "1246px", xs: "100%" },
          height: "540px",
          borderRadius: (theme) => theme.spacing(1.5),
        },
      }}
    >
      <Box display={"flex"} height={"100%"}>
        <Box width={"75%"} height={"100%"} position={"relative"}>
          <ImageLoad
            width={"100%"}
            height={"100%"}
            imageUrl={session.session_start_image_url || ""}
            alt={`Image of vehicle session start`}
          />
        </Box>
        <Box display={"flex"} width={"25%"} flexDirection={"column"} justifyContent={"space-between"}>
          <Box mt={1} height={"100%"}>
            {!!session.violation && vehicle.licensePlateNumber !== null && (
              <VehicleInformationBoxRow>
                <Typography variant="body1" color="text.primary">
                  License Plate
                </Typography>
                <Typography variant="body1" color="text.primary">
                  {vehicle.licensePlateRegion} {vehicle.licensePlateNumber}
                </Typography>
              </VehicleInformationBoxRow>
            )}
            <VehicleInformationBoxRow>
              <Typography variant="body1" color="text.primary">
                Vehicle Type
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ textTransform: "capitalize" }}>
                {formatVehicleType(vehicle.type)}
              </Typography>
            </VehicleInformationBoxRow>
            <VehicleInformationBoxRow>
              <Typography variant="body1" color="text.primary">
                Vehicle Color
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{ textTransform: "capitalize" }}>
                {formatVehicleColor(vehicle.color)}
              </Typography>
            </VehicleInformationBoxRow>
          </Box>
          <Box p={2}>
            <Button
              variant="contained"
              onClick={onClose}
              sx={{ mb: 1.25, width: "100%", textTransform: "capitalize", fontSize: "18px" }}
            >
              Close
            </Button>
            <Typography textAlign={"center"}>
              Something wrong?{" "}
              <Link
                sx={{ color: "primary.main", cursor: "pointer" }}
                href={process.env.REACT_APP_CURBSUITE_ZENDESK_SUPPORT_TICKET_URL}
              >
                Please Report
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export interface RealTimeOccupancyImageViewerDialogProps {
  // Indicates open/closed status of the component
  open: boolean;
  // Handler fired when user clicks the close button or otherwise clicks outside of the dialog
  onClose: () => void;
  /** Occupant being viewed */
  occupant: Occupant;
}

export default RealTimeOccupancyImageViewerDialog;
