import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import Box from "@mui/material/Box";

function a11yProps(index: number) {
  return {
    id: `realtime-occupancy-sidebar-tab-${index}`,
    "aria-controls": `realtime-occupancy-sidebar-tabpanel-${index}`,
    sx: { fontWeight: 500, textTransform: "none", width: "50%", minWidth: "133.5px", py: 1.375, px: 1 },
  };
}

export const RealTimeOccupancySidebarTabs: React.FC<RealTimeOccupancySidebarTabsProps> = ({
  loading = false,
  activeTab,
  onTabSelect,
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    onTabSelect(newValue);
  };

  return loading ? (
    <Box sx={{ width: "100%", display: "flex", gap: 4 }}>
      <LoadingSkeleton loading>
        <Box sx={(theme) => ({ width: theme.spacing(16.6875), height: theme.spacing(6) })} />
      </LoadingSkeleton>
      <LoadingSkeleton loading>
        <Box sx={(theme) => ({ width: theme.spacing(16.6875), height: theme.spacing(6) })} />
      </LoadingSkeleton>
    </Box>
  ) : (
    <Tabs sx={{ width: "100%" }} value={activeTab} onChange={handleChange} aria-label="RealTimeOccupancySidebarTabs">
      <Tab label="Overview" {...a11yProps(0)} />
      <Tab label="Real Time" {...a11yProps(1)} />
    </Tabs>
  );
};

export interface RealTimeOccupancySidebarTabsProps {
  loading?: boolean;
  activeTab: number;
  onTabSelect: (activeTab: number) => void;
}

export default RealTimeOccupancySidebarTabs;
