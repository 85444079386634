import React, { useEffect } from "react";
import { PayeeOverview } from "@app.automotus.io/components/PayeeOverview";
import { PayeeOverviewTabValue } from "@app.automotus.io/components/PayeeOverview/PayeeOverviewTabs";
import formatDate from "date-fns/format";
import Box from "@mui/material/Box";
import { useQuery } from "@apollo/client";
import {
  GET_CITATIONS,
  GET_INVOICES,
  GET_PAYEE_TRANSACTIONS_OVERVIEW,
  GetCitationsData,
  GetCitationsVars,
  GetInvoicesData,
  GetInvoicesVars,
  GetPayeeTransactionsOverviewData,
  GetPayeeTransactionsOverviewVars,
} from "common/graphql";
import { getOverviewPeriods } from "@app.automotus.io/components/routes/dashboard/utils";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";

export const DashboardLayout: React.FC = () => {
  const [now] = React.useState(new Date());
  const { userProfile } = useUserProfile();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isInvoicesTab = location.pathname.includes("/invoices");
  const isCitationsTab = location.pathname.includes("/citations");

  useEffect(() => {
    if (
      userProfile?.account?.id === process.env.REACT_APP_PIKEVILLE_ACCOUNT_ID &&
      !location.pathname.includes("/citations")
    ) {
      navigate("/dashboard/citations");
    }
  }, [userProfile, location, navigate]);

  const tab = (
    isInvoicesTab ? "invoices" : isCitationsTab ? "citations" : searchParams.get("tab") || "digital_wallet_transactions"
  ) as PayeeOverviewTabValue;

  const isDemoUser = !!(userProfile?.email || "").match(/\+demo@automotus.co/);

  const { curPeriodStart, curPeriodEnd, prevPeriodStart, prevPeriodEnd } = getOverviewPeriods(now);
  const {
    data: overviewData,
    loading: overviewLoading,
    error: overviewError,
    refetch: refetchOverview,
  } = useQuery<GetPayeeTransactionsOverviewData, GetPayeeTransactionsOverviewVars>(GET_PAYEE_TRANSACTIONS_OVERVIEW, {
    variables: {
      prevPeriodStartDate: formatDate(prevPeriodStart, "yyyy-MM-dd"),
      prevPeriodEndDate: formatDate(prevPeriodEnd, "yyyy-MM-dd"),
      curPeriodStartDate: formatDate(curPeriodStart, "yyyy-MM-dd"),
      curPeriodEndDate: formatDate(curPeriodEnd, "yyyy-MM-dd"),
    },
  });

  const {
    data: invoicesData,
    loading: invoicesLoading,
    error: invoicesError,
    refetch: refetchInvoices,
  } = useQuery<GetInvoicesData, GetInvoicesVars>(GET_INVOICES, {
    variables: {
      limit: 31,
      offset: 0,
      orderBy: [{ notice_sent_at: "desc" }],
    },
  });

  const {
    data: citationsData,
    loading: citationsLoading,
    error: citationsError,
    refetch: refetchCitations,
  } = useQuery<GetCitationsData, GetCitationsVars>(GET_CITATIONS, {
    variables: {
      limit: 31,
      offset: 0,
      orderBy: [{ notice_sent_at: "desc" }],
    },
  });

  const overviewSumKeyName =
    tab === "digital_wallet_transactions"
      ? "walletTransactionsAmount"
      : isDemoUser
      ? "potentialRevenueAmount"
      : "registeredTransactionsAmount";

  return (
    <Box sx={{ bgcolor: (theme) => theme.palette.grey[100], pt: 11 }}>
      <PayeeOverview
        tab={tab}
        error={Boolean(overviewError || invoicesError || citationsError)}
        onClickTryAgain={() => {
          refetchOverview();
          refetchInvoices();
          refetchCitations();
        }}
        onChangeTab={(newTab) => {
          if (newTab === "invoices") {
            navigate("/dashboard/invoices");
            return;
          } else if (newTab === "citations") {
            navigate("/dashboard/citations");
            return;
          } else {
            navigate(`/dashboard?tab=${newTab}`);
            return;
          }
        }}
        loading={overviewLoading || invoicesLoading || citationsLoading}
        percentageChangeSinceLastCycle={Math.abs(
          Math.round(
            (100 *
              ((overviewData?.curPeriodAggregate.aggregate.sum[overviewSumKeyName] || 0) -
                (overviewData?.prevPeriodAggregate.aggregate.sum[overviewSumKeyName] || 0))) /
              (overviewData?.prevPeriodAggregate.aggregate.sum[overviewSumKeyName] || 1),
          ),
        )}
        amount={
          tab === "invoices"
            ? invoicesData?.invoicesAggregate.aggregate.sum.amountPaid || 0
            : tab === "citations"
            ? citationsData?.citationsAggregate.aggregate.sum.paidAmount || 0
            : Math.abs(overviewData?.curPeriodAggregate.aggregate.sum[overviewSumKeyName] || 0)
        }
      />
      <Box display={"flex"} flexDirection={"row"} justifyContent={"center"} width={"100%"} sx={{ py: 10, px: 2.5 }}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"flex-start"}
          alignItems={"flex-start"}
          width={1120}
          maxWidth={"100%"}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
