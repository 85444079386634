import { useQuery } from "@apollo/client";
import formatDate from "date-fns/format";
import isAfter from "date-fns/isAfter";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars, InvoiceDisputeStatue } from "common/graphql";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Step from "@mui/material/Step";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import LoadingSkeleton from "../LoadingSkeleton";
import Paper from "@mui/material/Paper";
import VehicleInvoiceCardStatus from "../VehicleInvoice/VehicleInvoiceCardStatus";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  marginTop: -52,
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
      borderRadius: 2,
    },
  },
}));

export const InvoiceStatusStepper: React.FC<InvoiceStatusStepperProps> = ({
  invoiceId,
  isCollectionsEnabled: isCollectionsEnabled = true,
}) => {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  const invoice = invoiceData?.invoice;

  if (invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const noticeDate = new Date(invoice?.notice_sent_at || 0);
  const dueAt = new Date(invoice?.due_at || 0);
  const isPaid = !!invoice?.payment_transaction;
  const paidAt = new Date(invoice?.payment_transaction?.created_at || 0);

  // Only mark the invoice overdue if it was paid after the due date or if it is unpaid and the
  // due date has elapsed.
  const isOverdue = isPaid ? isAfter(paidAt, dueAt) : isAfter(new Date(), dueAt);

  const steps = [
    {
      label: "Notice Date",
      date: formatDate(noticeDate, "MM/dd/yyyy"),
      checked: true,
      showDate: true,
    },
    {
      label: "Overdue",
      date: formatDate(dueAt, "MM/dd/yyyy"),
      checked: invoice ? isOverdue : false,
      showDate: true,
    },
    {
      label: "Sent to Collections",
      date: formatDate(new Date(), "MM/dd/yyyy"),
      checked: false, // TODO: Need to implement a mechanism in place for sending bills to collections
      showDate: false,
    },
  ];

  if (invoice?.voided_at) {
    return (
      <VehicleInvoiceCardStatus
        message={
          <Typography>
            This invoice has been voided as the result of a resolved dispute.
            {invoice.replaced_by_invoice?.id && (
              <>
                {" "}
                <Link
                  component={RouterLink}
                  to={`/invoices/${invoice?.replaced_by_invoice?.id}`}
                  sx={{ color: "rgba(0,0,0,0.6)", textDecorationColor: "rgba(0,0,0,0.6)" }}
                >
                  View revised new invoice
                </Link>
                .
              </>
            )}
          </Typography>
        }
      />
    );
  }

  if (invoice?.dispute?.status === InvoiceDisputeStatue.PENDING) {
    return (
      <VehicleInvoiceCardStatus
        status="warning"
        message={<Typography>We’re currently reviewing this invoice.</Typography>}
      />
    );
  }

  return (
    <Paper
      sx={{
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box mb={3} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={invoiceLoading} height={60}>
          <Typography variant="h5" fontWeight={400}>
            Invoice Status
          </Typography>
        </LoadingSkeleton>
        {!invoiceLoading && (
          <Typography
            variant="h6"
            color={invoice?.payment_transaction?.created_at ? "#3A833C" : "error.main"}
            fontSize="1.25rem"
            fontWeight={400}
          >
            {invoice?.payment_transaction?.created_at
              ? `Paid on ${formatDate(new Date(invoice?.payment_transaction?.created_at || 0), "MM/dd/yyyy")}`
              : "Unpaid"}
          </Typography>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        {!invoiceLoading && (
          <Stepper connector={<CustomStepConnector />} sx={{ width: "100%" }}>
            {steps.slice(0, 3 - (isCollectionsEnabled ? 0 : 1)).map((step) => (
              <Step key={step.label} completed={step.checked}>
                <StepLabel sx={{ flexDirection: "column", textAlign: "center" }}>
                  <Typography variant="subtitle2" fontWeight={500} mt={1}>
                    {step.label}
                  </Typography>
                  <Typography variant="caption" sx={{ visibility: step.showDate ? "initial" : "hidden" }}>
                    {step.date}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        {invoiceLoading &&
          [0, 1, 2, 3].map((index) => <Skeleton variant="circular" width={36} height={36} key={index} />)}
      </Box>
    </Paper>
  );
};

export interface InvoiceStatusStepperProps {
  invoiceId: string;
  isCollectionsEnabled?: boolean; // TODO: Should be removed once we implemented SentCollections logic
}

export default InvoiceStatusStepper;
