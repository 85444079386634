import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";
import getDaysInMonth from "date-fns/getDaysInMonth";
import endOfMonth from "date-fns/endOfMonth";

/**
 * Gets periods used to define the query that populates the payee overview display.
 * @param now Current date
 * @returns An object describing the periods used to define the overview query.
 */
export function getOverviewPeriods(now: Date): OverviewPeriods {
  const curPeriodStart = startOfMonth(now);
  const curPeriodEnd = now;

  const curDayOfMonth = now.getDate();

  const prevPeriodStart = startOfMonth(subMonths(curPeriodStart, 1));
  const daysInPreviousMonth = getDaysInMonth(prevPeriodStart);
  // The current period constitutes the running total of transactions in the current month, while the previous period
  // corresponds to the running total from the previous month up to an offset from the start of the previous month
  // equivalent to the current time's offset from the start of the current month. In certain cases, the size offset of the
  // current time relative to the start of the current month exceeds the entire span of the previous month (e.g. if
  // the current date is March 31st.) In such cases, we simply use the entire span of the previous month to define the
  // previous period.
  const prevPeriodEnd = curDayOfMonth > daysInPreviousMonth ? endOfMonth(prevPeriodStart) : subMonths(curPeriodEnd, 1);

  return {
    curPeriodStart,
    curPeriodEnd,
    prevPeriodStart,
    prevPeriodEnd,
  };
}

/** Periods defining the query used to populate the payee overview display */
export interface OverviewPeriods {
  /** Start of the current period */
  curPeriodStart: Date;
  /** End of the current period */
  curPeriodEnd: Date;
  /** Start of the previous period */
  prevPeriodStart: Date;
  /** End of the previous period */
  prevPeriodEnd: Date;
}
