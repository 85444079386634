import React, { useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_INVOICE_BY_ID,
  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_IMAGES,
  GetInvoiceByIdData,
  GetInvoiceByIdVars,
  GetTransactionDetailData,
  GetTransactionDetailVars,
  GetTransactionImagesData,
  GetTransactionImagesVars,
} from "common/graphql";
import Paper from "@mui/material/Paper";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransactionImageCarousel from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionImageCarousel";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";

export const InvoiceSessionImageCarousel: React.FC<InvoiceSessionImageCarouselProps> = ({
  invoiceId,
  transactionId,
}) => {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
    onError(err) {
      console.error("failed to load invoice", err);
    },
  });

  const {
    data: transactionData,
    loading: transactionLoading,
    error: transactionError,
    refetch: refetchTransaction,
  } = useQuery<GetTransactionDetailData, GetTransactionDetailVars>(GET_TRANSACTION_DETAIL, {
    variables: {
      transactionId,
    },
  });

  const [getTransactionImages, { data: imagesData, loading: imagesLoading, error: getImagesError }] = useMutation<
    GetTransactionImagesData,
    GetTransactionImagesVars
  >(GET_TRANSACTION_IMAGES, {
    onError(err) {
      console.error("failed to fetch transaction images", err);
    },
  });

  useEffect(() => {
    getTransactionImages({ variables: { transactionId } });
  }, [getTransactionImages, transactionId]);

  const error = getImagesError || transactionError || invoiceError;
  const loading = imagesLoading || transactionLoading || invoiceLoading;

  return (
    <Paper sx={{ p: 3 }}>
      {error ? (
        <LocalLevelError
          onTryAgain={() => {
            getTransactionImages({ variables: { transactionId } });
            refetchTransaction();
            refetchInvoice();
          }}
        />
      ) : (
        <Stack spacing={3}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <LoadingSkeleton loading={loading}>
              <Typography variant={"h5"} fontWeight={400}>
                Invoice Number: {`${invoiceData?.invoice?.index}`.padStart(6, "0")}
              </Typography>
            </LoadingSkeleton>
          </Box>
          <TransactionImageCarousel
            loading={loading}
            height={404}
            parkStartDate={transactionData && new Date(transactionData.transaction?.sessionStartedAtLocal || 0)}
            parkEndDate={transactionData && new Date(transactionData.transaction?.sessionEndedAtLocal || 0)}
            licensePlateCaptureDate={
              transactionData && new Date(transactionData.transaction?.sessionStartedAtLocal || 0)
            }
            parkStartImageSrc={imagesData?.transactionImages?.sessionStartImageUrl}
            parkEndImageSrc={imagesData?.transactionImages?.sessionEndImageUrl}
            licensePlateCaptureImageSrc={imagesData?.transactionImages?.licensePlateImageUrl}
          />
        </Stack>
      )}
    </Paper>
  );
};

export interface InvoiceSessionImageCarouselProps {
  invoiceId: string;
  transactionId: string;
}

export default InvoiceSessionImageCarousel;
