import React from "react";
import LocalLevelError, { LocalLevelErrorProps } from "@app.automotus.io/components/common/LocalLevelError";
import Box from "@mui/material/Box";

export const TableErrorOverlay: React.FC<TableErrorOverlayProps> = (props) => (
  <Box
    sx={{
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center ",
    }}
  >
    <LocalLevelError {...props} />
  </Box>
);

export type TableErrorOverlayProps = LocalLevelErrorProps;

export default TableErrorOverlay;
