import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import RealTimeOccupancyEnforcementTooltip from "../RealTimeOccupancyEnforcementTooltip";
import { gql, useLazyQuery } from "@apollo/client";

export const RealTimeOccupancyPageHeader: React.FC<RealTimeOccupancyPageHeaderProps> = ({ accountId }) => {
  const [getEnforcementConfig, { data: enforcementConfigData, loading: enforcementConfigLoading }] = useLazyQuery<
    GetIsAutomatedEnforcementEnabledData,
    GetIsAutomatedEnforcementEnabledVars
  >(GET_IS_AUTOMATED_ENFORCEMENT_ENABLED);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (accountId) {
      getEnforcementConfig({ variables: { accountId } });
    }
  }, [getEnforcementConfig, accountId]);

  const loading = !accountId || enforcementConfigLoading;
  const automatedEnforcementActive = enforcementConfigData?.account?.isAutomatedEnforcementEnabled ?? false;

  return loading ? (
    <Skeleton variant="rectangular" width={"100%"} height={"44px"}>
      <Box width={"100%"} height={"44px"} />
    </Skeleton>
  ) : (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Typography variant="h4" color="text.primary">
        Real Time Vehicle Occupancy
      </Typography>
      <Box sx={{ display: "flex", gap: (theme) => theme.spacing(1.25) }}>
        <Typography variant="body1" color="text.secondary">
          Automated Enforcement:
        </Typography>
        {!automatedEnforcementActive ? (
          <Typography variant="body1" color="error.main">
            Inactive
          </Typography>
        ) : (
          <RealTimeOccupancyEnforcementTooltip open={open} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
            <Typography variant="body1" color="success.main">
              Active
            </Typography>
          </RealTimeOccupancyEnforcementTooltip>
        )}
      </Box>
    </Box>
  );
};

export const GET_IS_AUTOMATED_ENFORCEMENT_ENABLED = gql`
  query GetIsAutomatedEnforcementEnabled($accountId: uuid!) {
    account: account_by_pk(id: $accountId) {
      id
      isAutomatedEnforcementEnabled: is_automated_enforcement_enabled
    }
  }
`;

interface GetIsAutomatedEnforcementEnabledData {
  account: {
    id: string;
    isAutomatedEnforcementEnabled: boolean;
  } | null;
}

interface GetIsAutomatedEnforcementEnabledVars {
  accountId: string;
}

export interface RealTimeOccupancyPageHeaderProps {
  accountId?: string;
}

export default RealTimeOccupancyPageHeader;
