import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

export const RealTimeOccupancyMapFilterAlert: React.FC<RealTimeOccupancyMapFilterAlertProps> = ({
  onClickResetFilters,
}) => {
  return (
    <Alert
      severity="info"
      action={
        <Button
          variant="text"
          sx={{
            color: "primary.main",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            px: 0.625,
            py: 0.5,
            textTransform: "none",
          }}
          onClick={onClickResetFilters}
        >
          Reset Filters
        </Button>
      }
      sx={{
        width: "100%",
        py: 0,
        ".MuiAlert-message": {
          py: 1.125,
        },
        ".MuiAlert-action": {
          mr: 0,
        },
        ".MuiSvgIcon-root": {
          fontSize: "22px",
          color: "primary.main",
        },
        alignItems: "center",
      }}
    >
      <Typography variant="body4" color="primary.main">
        Filter applied. Some realtime information may be hidden
      </Typography>
    </Alert>
  );
};

export interface RealTimeOccupancyMapFilterAlertProps {
  onClickResetFilters: () => void;
}

export default RealTimeOccupancyMapFilterAlert;
