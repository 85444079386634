export const getViolationTypeLabel = (violationType: string): string => {
  switch (violationType) {
    case "double_park":
      return "Double park";
    case "bike_lane":
      return "Bike lane";
    case "no_stopping":
      return "No stopping";
    case "unregistered_vehicle":
      return "Unregistered Vehicle";
    case "exceeds_max_time":
      return "Dwell time";
    default:
      return violationType;
  }
};
