import { gql } from "@apollo/client";
import { TransactionDetail, transactionDetailFragment } from "common/graphql/fragments/transactionDetailFragment";
import { parkFragment, Park } from "common/graphql/fragments/parkFragment";
import citationSummaryFragment, { CitationSummary } from "common/graphql/fragments/citation/citationSummaryFragment";

export const citationDetailFragment = gql`
  fragment citationDetailFragment on enforcement_citation {
    ...citationSummaryFragment
    sentToCollectionsAt: sent_to_collections_at
    sessionTransaction: session_transaction {
      ...transactionDetailFragment
    }
    dispute {
      status
    }
    park {
      ...parkFragment
    }
    isSessionTransactionBillable: is_session_transaction_billable
  }
  ${citationSummaryFragment}
  ${transactionDetailFragment}
  ${parkFragment}
`;

export interface CitationDetail extends CitationSummary {
  sentToCollectionsAt?: string;
  sessionTransaction: TransactionDetail;
  park?: Park;
  isSessionTransactionBillable: boolean;
}

export default citationDetailFragment;
