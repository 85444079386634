import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import LoadingSkeleton from "../LoadingSkeleton";
import { formatCurrency } from "common/format";
import { grey } from "@mui/material/colors";
import differenceInMinutes from "date-fns/differenceInMinutes";

export const InvoiceGrandTotal: React.FC<InvoiceGrandTotalProps> = ({ invoiceId }) => {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  const invoice = invoiceData?.invoice;

  if (invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const totalParkPeriodMinutes =
    invoice?.transactions.reduce((totalTime, transaction) => {
      return (
        totalTime +
        differenceInMinutes(new Date(transaction.park.endTime || 0), new Date(transaction.park.startTime || 0))
      );
    }, 0) || 0;

  return (
    <Paper
      sx={{
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box px={3} pt={3} pb={1.5} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={invoiceLoading} height={60}>
          <Typography variant="h5" fontWeight={400}>
            Grand Total
          </Typography>
        </LoadingSkeleton>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1.25}
        pb={1}
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <Box>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography variant="subtitle1" fontWeight={600}>
              Total Park Period
            </Typography>
            <Typography variant="body1">{`${totalParkPeriodMinutes} min`}</Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography variant="subtitle1" fontWeight={600} textAlign={"right"}>
              Amount
            </Typography>
            <Typography variant="body1" textAlign={"right"}>
              ${formatCurrency(invoice?.transaction_amount_due || 0)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        pt={1}
        pb={1.25}
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <Box>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography variant="subtitle1" fontWeight={500}>
              Admin Fee
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography variant="body1">${formatCurrency(invoice?.admin_fee_due || 0)}</Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        px={3}
        py={2.5}
        sx={{
          backgroundColor: "rgba(13, 85, 191, 0.08)",
        }}
      >
        <Box>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography variant="h6" fontWeight={400}>
              Total Invoice Amount
            </Typography>
          </LoadingSkeleton>
        </Box>
        <Box>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography variant="h6" fontWeight={400}>
              ${formatCurrency(invoice?.total_amount_due || 0)}
            </Typography>
          </LoadingSkeleton>
        </Box>
      </Box>
    </Paper>
  );
};

export interface InvoiceGrandTotalProps {
  invoiceId: string;
}

export default InvoiceGrandTotal;
