import React from "react";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";

import { useTableauToken } from "@app.automotus.io/components/hooks";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { decodeViewNameFromPathComponent } from "./util";
import CircularProgress from "@mui/material/CircularProgress";

export const TableauView: React.FC = () => {
  const { view = "" } = useParams<{ view: string }>();

  const viewName = decodeViewNameFromPathComponent(view);

  const {
    data: tableauViewData,
    loading: tableauViewLoading,
    refetch: refetchTableauView,
    error: tableauViewError,
  } = useQuery<GetTableauViewByNameData>(GET_TABLEAU_VIEW_BY_NAME, {
    variables: { name: viewName },
  });

  const { token, loading: tokenLoading, error: tokenError, refetch: refetchToken } = useTableauToken();

  const refetchOnError = () => {
    if (tokenError) {
      refetchToken();
    }
    if (tableauViewError) {
      refetchTableauView();
    }
  };

  if (tableauViewError || tokenError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const loading = tokenLoading || tableauViewLoading;

  return (
    <Box>
      <Box sx={{ my: { xs: 3, tiny: 3, sm: 3, md: 4 } }}>
        {loading ? (
          <Box display={"flex"} justifyContent={"center"}>
            <CircularProgress />
          </Box>
        ) : (
          tableauViewData &&
          token && (
            <tableau-viz
              src={tableauViewData.tableau_workbook_view[0].uri}
              token={token}
              hide-tabs={true}
              width="1060px"
              height="800px"
            />
          )
        )}
      </Box>
    </Box>
  );
};

/** Query to retrieve tableau view by name from Hasura */
const GET_TABLEAU_VIEW_BY_NAME = gql`
  query GetTableauViewByName($name: String!) {
    tableau_workbook_view(where: { name: { _eq: $name } }, limit: 1) {
      id
      name
      uri
    }
  }
`;

/** Result type of the {@link GET_TABLEAU_VIEW_BY_NAME} query */
interface GetTableauViewByNameData {
  tableau_workbook_view: {
    /** Unique ID of the view */
    id: string;
    /** Name of the view */
    name: string;
    /** Fully-qualified URI of the view */
    uri: string;
  }[];
}

export default TableauView;
