import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import React from "react";
import LocalLevelError from "../common/LocalLevelError";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import LoadingSkeleton from "../LoadingSkeleton";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

export const InvoiceVehicleDetails: React.FC<InvoiceVehicleDetailsProps> = ({ invoiceId }) => {
  const {
    data: invoiceData,
    loading: invoiceLoading,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  if (invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const invoice = invoiceData?.invoice;
  const vehicle = invoice?.registered_vehicle;
  const isVehicleRegistered = Boolean(
    vehicle?.accounts?.length && !vehicle.accounts[0].attachment_type.includes("non_registration"),
  );

  return (
    <Paper
      sx={{
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Box mb={3} display="flex" justifyContent="space-between">
        <LoadingSkeleton loading={invoiceLoading} height={60}>
          <Typography variant="h5" fontWeight={400}>
            Vehicle Details
          </Typography>
        </LoadingSkeleton>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="row">
        <LoadingSkeleton loading={invoiceLoading} width={105} height={60}>
          <Typography variant="body1" fontWeight={400}>
            License Plate Number
          </Typography>
        </LoadingSkeleton>
        <LoadingSkeleton loading={invoiceLoading} width={105} height={60}>
          <Typography variant="body1" fontWeight={400}>
            {`${vehicle?.state_code} ${vehicle?.license_plate}`}
          </Typography>
        </LoadingSkeleton>
      </Box>
      {!invoiceLoading && !isVehicleRegistered && (
        <Box mt={3} p={2} sx={{ background: "rgba(244, 67, 54, 0.08)", display: "flex" }}>
          <InfoOutlined sx={{ fontSize: "24px", color: "error.main", pr: 1.5 }} />
          <Typography variant="body1" color="error.main">
            This vehicle is currently unregistered.
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export interface InvoiceVehicleDetailsProps {
  invoiceId: string;
}

export default InvoiceVehicleDetails;
