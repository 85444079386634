import React from "react";
import Box from "@mui/material/Box";
import { RealTimeOccupancySpaceStatus } from "../RealTimeOccupancySpaceStatusIndicator";

const statusColorMap = {
  available: "#9E9E9E",
  occupied: "#3A833C",
  occluded: "transparent",
  violation: "#E31B0C",
  doubleparked: "transparent",
  zoneSelected: "#FFFF",
  selected: "#FFFF",
};

const statusBorderColorMap = {
  available: "#9E9E9E",
  occupied: "#3A833C",
  occluded: "#3A833C",
  violation: "#E31B0C",
  doubleparked: "transparent",
  zoneSelected: "#0D55BF",
  selected: "#0D55BF",
};

const statusBorderWidthMap = {
  available: "0px",
  occupied: "0px",
  occluded: "2px",
  violation: "0px",
  doubleparked: "0px",
  zoneSelected: "3px",
  selected: "4px",
};

const zIndexOnBasicOfStatus = {
  occluded: 1,
  available: 2,
  occupied: 3,
  doubleparked: 4,
  violation: 5,
  zoneSelected: 6,
  selected: 7,
};

export const RealTimeOccupancySpaceStatusMapMarker: React.FC<RealTimeOccupancySpaceStatusMapMarkerProps> = ({
  status,
}) => {
  return (
    <Box
      sx={(theme) => ({
        width: theme.spacing(2.35),
        height: theme.spacing(2.35),
        borderRadius: "50%",
        borderWidth: statusBorderWidthMap[status],
        borderStyle: "solid",
        borderColor: statusBorderColorMap[status],
        backgroundColor: statusColorMap[status],
        boxSizing: "border-box",
        zIndex: zIndexOnBasicOfStatus[status],
      })}
    />
  );
};

export interface RealTimeOccupancySpaceStatusMapMarkerProps {
  status: RealTimeOccupancySpaceStatus;
}

export default RealTimeOccupancySpaceStatusMapMarker;
