import React from "react";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import YearSelect from "@app.automotus.io/components/MonthYearSelectMenu/YearSelect";
import getYear from "date-fns/getYear";
import Divider from "@mui/material/Divider";
import times from "lodash/times";
import formatDate from "date-fns/format";
import startOfMonth from "date-fns/startOfMonth";

const MIN_DATE = new Date(2000, 0);
const MAX_DATE = new Date(3000, 0);

/**
 * Renders a menu that allows a user to select a year and a month.
 */
export const MonthYearSelectMenu: React.FC<MonthYearSelectMenuProps> = ({
  year,
  month,
  onChange = () => undefined,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  ...menuProps
}) => {
  const handleChangeYear = (newYear: number) => {
    onChange(new Date(newYear, month || 0));
  };

  const handleChangeMonth = (newMonth: number) => {
    onChange(new Date(year || getYear(new Date()), newMonth));
  };

  const monthOptions = times(12, (monthIndex) => ({
    monthIndex,
    label: formatDate(new Date(year || getYear(new Date()), monthIndex), "MMMM"),
    startOfMonth: startOfMonth(new Date(year || getYear(new Date()), monthIndex)),
    onClick: () => {
      handleChangeMonth(monthIndex);
    },
  })).filter(
    ({ startOfMonth }) => startOfMonth.getTime() >= minDate.getTime() && startOfMonth.getTime() <= maxDate.getTime(),
  );

  return (
    <Menu {...menuProps}>
      <YearSelect year={year} minYear={getYear(minDate)} maxYear={getYear(maxDate)} onChange={handleChangeYear} />
      <Divider orientation={"horizontal"} sx={{ mt: 0.75 }} />
      {monthOptions.map(({ label, onClick, monthIndex }) => (
        <MenuItem key={monthIndex} onClick={onClick} selected={monthIndex === month}>
          {label}
        </MenuItem>
      ))}
    </Menu>
  );
};

/**
 * Props passed to initialize a {@link MonthYearSelectMenu} component instance.
 */
export interface MonthYearSelectMenuProps extends Omit<MenuProps, "onChange"> {
  year?: number | null;
  month?: number | null;
  /** Handler fired upon change of date */
  onChange?: (date: Date) => void;
  /** Minimum selectable date (inclusive) */
  minDate?: Date;
  /** Maximum selectable date (inclusive) */
  maxDate?: Date;
}

export default MonthYearSelectMenu;
