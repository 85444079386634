import React, { useEffect } from "react";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import Box from "@mui/material/Box";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { gql, useQuery } from "@apollo/client";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";
import {
  decodeViewNameFromPathComponent,
  encodeViewNameAsPathComponent,
} from "@app.automotus.io/components/routes/analytics/util";
import TabsLoading from "@app.automotus.io/components/routes/analytics/TabsLoading";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

export const Analytics: React.FC = () => {
  const navigate = useNavigate();
  const match = useMatch("/analytics/:viewName");
  const { userProfile, loading: profileLoading, refetch: refetchProfile, error: profileError } = useUserProfile();
  const {
    data: tableauViewsData,
    loading: tableauViewsLoading,
    refetch: refetchTableauViews,
    error: tableauViewsError,
  } = useQuery<GetTableauViewsData>(GET_TABLEAU_VIEWS);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://10az.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
    script.type = "module";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    /** Upon loading of tableauViewsData, reroute the user to the first available view */
    if (!match && tableauViewsData && tableauViewsData.tableauViews.length > 0) {
      navigate(encodeViewNameAsPathComponent(tableauViewsData.tableauViews[0].name), { replace: true });
    }
  }, [match, navigate, tableauViewsData]);

  if (profileLoading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  if (profileError || tableauViewsError) {
    return (
      <LocalLevelError
        onTryAgain={() => {
          if (profileError) {
            refetchProfile();
          }
          if (tableauViewsError) {
            refetchTableauViews();
          }
        }}
      />
    );
  }

  const userAccountRoleType = userProfile?.account?.roleType;

  if (userAccountRoleType !== "payee") {
    return (
      <Typography variant="h6" sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        You do not have privileges to access this page.
      </Typography>
    );
  }

  const { tableauViews } = tableauViewsData || {};

  return (
    <Box sx={{ bgcolor: (theme) => theme.palette.grey[100], pt: 4 }}>
      <Box
        sx={{ bgcolor: (theme) => theme.palette.background.paper, pt: 7.5 }}
        display={"flex"}
        justifyContent={"center"}
      >
        <Box sx={{ width: 1060 }}>
          <Box display={"flex"} justifyContent={"center"}>
            {tableauViewsLoading ? (
              <TabsLoading />
            ) : (
              <>
                <Tabs value={match ? decodeViewNameFromPathComponent(match.params.viewName || "") : false} centered>
                  {(tableauViews || []).map(({ id, name }, i) => (
                    <LinkTab
                      key={id}
                      label={name}
                      value={name}
                      id={`view-tab-${i}`}
                      to={encodeViewNameAsPathComponent(name)}
                    />
                  ))}
                </Tabs>
              </>
            )}
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

/** A tab that wraps a react router link component */
function LinkTab(props: LinkTabProps) {
  return <Tab component={Link} {...props} />;
}

/** Props passed to initialize a {@link LinkTab} */
interface LinkTabProps {
  label?: string;
  value?: string;
  id?: string;
  to: string;
}

/** Query to retrieve tableau views from Hasura */
const GET_TABLEAU_VIEWS = gql`
  query GetTableauViews {
    tableauViews: tableau_workbook_view(order_by: [{ name: asc }]) {
      id
      name
      uri
    }
  }
`;

/** Result type of the {@link GET_TABLEAU_VIEWS} query */
interface GetTableauViewsData {
  tableauViews: {
    /** Unique ID of the view */
    id: string;
    /** Name of the view */
    name: string;
    /** Fully-qualified URI of the view */
    uri: string;
  }[];
}

export default Analytics;
