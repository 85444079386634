import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(2),
    gap: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FFB400",
    width: "224px",
    borderRadius: theme.spacing(2),
    marginRight: theme.spacing(-2),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFB400",
    fontSize: "16px",
  },
}));

export const RealTimeOccupancyEnforcementTooltip: React.FC<RealTimeOccupancyEnforcementTooltipProps> = ({
  open,
  onClose,
  children,
}) => {
  return (
    <CustomTooltip
      open={open}
      arrow
      placement="bottom-end"
      title={
        <>
          <Typography variant="body1" color="text.primary">
            Violations are enforced by Automotus. No further action is needed.
          </Typography>
          <Button
            variant="outlined"
            sx={{
              width: "fit-content",
              px: "5px",
              py: "4px",
              color: "text.primary",
              borderRadius: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.04)",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "22px",
              letterSpacing: "0.46px",
              alignSelf: "flex-end",
              border: "0px",
              ":hover": {
                border: "0px",
                backgroundColor: "rgba(0, 0, 0, 0.08)",
              },

              ":focus": {
                border: "0px",
              },

              textTransform: "none",
            }}
            onClick={onClose}
          >
            Got it
          </Button>
        </>
      }
    >
      {children}
    </CustomTooltip>
  );
};

export interface RealTimeOccupancyEnforcementTooltipProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  children: React.ReactElement;
}

export default RealTimeOccupancyEnforcementTooltip;
