import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import React from "react";
import IconButton from "@mui/material/IconButton";
import { convertTimeToString } from "common/format/time";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import _times from "lodash/times";
import { OfflineZone } from "common/graphql/queries";
import SyncInProgressTooltip from "../SyncInProgressTooltip";

export const BoxRow: React.FC = ({ children }) => {
  return (
    <Box
      width={"100%"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
      px={2}
      pt={2}
      pb={1.5}
      sx={(theme) => ({
        borderBottomColor: alpha(theme.palette.text.primary, 0.23),
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
      })}
      boxSizing={"border-box"}
      height={(theme) => theme.spacing(8.375)}
    >
      {children}
    </Box>
  );
};

export const RealTimeOccupancyOfflineZonesSidebar: React.FC<RealTimeOccupancyOfflineZonesSidebarProps> = ({
  loading,
  isRefreshing,
  zones,
  contactUsHref,
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;
  return (
    <Box
      sx={{
        position: "absolute",
        width: "332px",
        height: "100%",
        top: 0,
        right: 0,
        backgroundColor: "#ffff",
        display: "flex",
        flexDirection: "column",
        zIndex: 100,
      }}
    >
      <LoadingSkeleton loading={loading} width={"calc(100% - 32px)"} height={"40px"} sx={{ transform: "none", mx: 2 }}>
        <Box
          px={2}
          py={1.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={(theme) => ({
            borderBottomColor: alpha(theme.palette.text.primary, 0.23),
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          })}
          boxSizing={"border-box"}
          height={(theme) => theme.spacing(5.5)}
        >
          <Box display={"flex"} gap={0.5} alignItems={"center"}>
            <Typography variant="subtitle2" color="text.secondary" fontWeight={500}>
              Zones Offline
            </Typography>
            {isRefreshing && <SyncInProgressTooltip />}
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: "20px", color: "action.active" }} />
          </IconButton>
        </Box>
      </LoadingSkeleton>
      <Box sx={{ overflowY: "auto", alignItems: "flex-start", flex: 1 }}>
        {loading ? (
          _times(3, (i) => (
            <LoadingSkeleton
              key={i}
              loading={loading}
              width={"calc(100% - 32px)"}
              height={"40px"}
              sx={{ transform: "none", mx: 2, mt: 2.5 }}
            />
          ))
        ) : zones?.length ? (
          zones?.map((zone) => (
            <BoxRow key={zone.id}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="body1" color="text.primary">
                  {zone.id}
                </Typography>
                <Typography variant="body4" color="action.active">
                  {zone.street_address}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                color="text.primary"
                sx={{
                  minWidth: "70px",
                }}
              >
                {convertTimeToString(new Date(zone.offlineSince))}
              </Typography>
            </BoxRow>
          ))
        ) : (
          <Typography variant="body1" p={2}>
            None
          </Typography>
        )}
      </Box>
      <LoadingSkeleton loading={loading} height={"18px"} sx={{ transform: "none", mx: "auto" }}>
        <Box p={2}>
          <Typography textAlign={"center"}>
            For more details{" "}
            <Link color="primary" underline="always" href={contactUsHref || "#"} target="_blank">
              contact us
            </Link>
          </Typography>
        </Box>
      </LoadingSkeleton>
    </Box>
  );
};

export interface RealTimeOccupancyOfflineZonesSidebarProps {
  // Boolean indicating loading state
  loading: boolean;
  // Boolean indicating whether to show offline zones as refreshing
  isRefreshing?: boolean;
  // An array of offline zones. Data type described below.
  zones?: OfflineZone[];
  // The link that the user should be taken to upon clicking the contact us button
  contactUsHref?: string;
  // Boolean indicating the open/closed status of the sidebar
  isOpen: boolean;
  // Handler fired upon close of the sidebar
  onClose: () => void;
}

export default RealTimeOccupancyOfflineZonesSidebar;
