/**
 * Encodes a view name as a url path component
 * @param viewName Name of the view
 * @returns The view name encoded as a URL path component
 */
export function encodeViewNameAsPathComponent(viewName: string): string {
  return viewName.replaceAll(" ", "_");
}

/**
 * Decodes a view name from a url path component
 * @param urlPathComponent Path component to decode as view name
 * @returns The view name decoded from a URL path component
 */
export function decodeViewNameFromPathComponent(urlPathComponent: string): string {
  return urlPathComponent.replaceAll("_", " ");
}
