import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import RealTimeOccupancySpaceStatusIndicator, {
  RealTimeOccupancySpaceStatus,
} from "../RealTimeOccupancySpaceStatusIndicator";
import Typography from "@mui/material/Typography";

const RealTimeOccupancyMapStatuses: Record<RealTimeOccupancySpaceStatus, string> = {
  available: "Available",
  occupied: "Occupancy Detected",
  occluded: "Occlusion Detected",
  violation: "Violation Detected",
  doubleparked: "Double Park Violation",
  zoneSelected: "Selected",
  selected: "Selected",
};

export const RealTimeOccupancyMapLegend: React.FC<RealTimeOccupancyMapLegendProps> = ({ loading, filterStatuses }) => {
  return loading ? (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={"44px"}
      sx={{
        maxWidth: "750px",
      }}
    >
      <Box width={"100%"} height={"44px"} />
    </Skeleton>
  ) : (
    <Box display={"flex"} alignItems={"center"}>
      {filterStatuses.map((status) => (
        <Box key={status} display={"flex"} gap={0.5} px={"10px"} py={"12px"} alignItems={"center"}>
          <RealTimeOccupancySpaceStatusIndicator status={status} />
          <Typography variant={"body1"}>{RealTimeOccupancyMapStatuses[status]}</Typography>
        </Box>
      ))}
      <Box display={"flex"} gap={0.5} px={"10px"} py={"12px"} alignItems={"center"}>
        <RealTimeOccupancySpaceStatusIndicator status={"selected"} />
        <Typography variant={"body1"}>{RealTimeOccupancyMapStatuses["selected"]}</Typography>
      </Box>
    </Box>
  );
};

export interface RealTimeOccupancyMapLegendProps {
  loading: boolean;
  filterStatuses: RealTimeOccupancySpaceStatus[];
}

export default RealTimeOccupancyMapLegend;
