import startOfDay from "date-fns/startOfDay";
import isAfter from "date-fns/isAfter";
import addDays from "date-fns/addDays";

/**
 * Generates an array of all dates (in local time) between the start of day in local time of the provided date "since"
 * up to and including the start of the day in local time of the provided date "until." Each element in the result set
 * will be a date representing the start of the given day in the range.
 * @param since Start of range
 * @param until End of range
 */
export function generateDatesInRange(since: Date, until: Date): Date[] {
  const firstInRange = startOfDay(since);
  const lastInRange = startOfDay(until);

  const daysInRange: Date[] = [];
  for (let cur = firstInRange; !isAfter(cur, lastInRange); cur = addDays(cur, 1)) {
    daysInRange.push(cur);
  }

  return daysInRange;
}

export default generateDatesInRange;
