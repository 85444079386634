import * as React from "react";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export interface RealTimeOccupancySidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

export const RealTimeOccupancySidebar: React.FC<RealTimeOccupancySidebarProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      <Box
        sx={{
          display: isOpen ? "block" : "none",
          position: "absolute",
          width: "332px",
          height: "100%",
          minHeight: "682px",
          top: 0,
          left: 0,
          backgroundColor: (theme) => theme.palette.background.default,
          overflow: "hidden",
          zIndex: 100,
        }}
      >
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 100,
          }}
        >
          <CloseIcon sx={{ fontSize: "20px", color: (theme) => theme.palette.grey[500] }} />
        </IconButton>
        {children}
      </Box>
    </>
  );
};

export default RealTimeOccupancySidebar;
