import React from "react";
import Stack from "@mui/material/Stack";
import { InvoiceStatusStepper } from "@app.automotus.io/components/InvoiceStatusStepper";
import { useNavigate, useParams } from "react-router-dom";
import { TransactionTimeAndRateBreakdown } from "@app.automotus.io/components/TransactionDetail/TransactionTimeAndRateBreakdown";
import { useQuery } from "@apollo/client";
import {
  GET_INVOICE_BY_ID,
  GET_TRANSACTION_DETAIL,
  GetInvoiceByIdData,
  GetInvoiceByIdVars,
  GetTransactionDetailData,
  GetTransactionDetailVars,
} from "common/graphql";
import TransactionTotal from "@app.automotus.io/components/TransactionDetail/TransactionTotal";
import sumBy from "lodash/sumBy";
import differenceInSeconds from "date-fns/differenceInSeconds";
import Paper from "@mui/material/Paper";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { InvoiceSessionDetails } from "@app.automotus.io/components/InvoiceSessionDetails";
import { InvoiceSessionImageCarousel } from "@app.automotus.io/components/InvoiceSessionImageCarousel";

export const InvoiceTransaction: React.FC = () => {
  const { invoiceId = "", transactionId = "" } = useParams<{ invoiceId: string; transactionId: string }>();
  const navigate = useNavigate();
  const {
    loading: invoiceLoading,
    error: invoiceError,
    refetch: refetchInvoice,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
    onError(err) {
      console.error("failed to load invoice", err);
    },
  });
  const {
    data: transactionData,
    loading: transactionLoading,
    error: transactionError,
    refetch: refetchTransaction,
  } = useQuery<GetTransactionDetailData, GetTransactionDetailVars>(GET_TRANSACTION_DETAIL, {
    variables: {
      transactionId,
    },
    onError(err) {
      console.error("failed to load transaction", err);
    },
  });

  // TODO: handle case when transaction and or invoice could not be found

  const error = invoiceError || transactionError;
  const handleTryAgain = async () => {
    await Promise.all([refetchInvoice(), refetchTransaction()]);
  };

  if (error) {
    return (
      <Paper sx={{ width: "100%" }}>
        <LocalLevelError onTryAgain={handleTryAgain} />
      </Paper>
    );
  }

  const loading = invoiceLoading || transactionLoading;

  const handleClickBack = () => {
    navigate(`/dashboard/invoices/${invoiceId}`, { replace: true });
  };

  const isFreeTransaction = transactionData?.transaction?.tags.some(({ tag }) => tag === "first_park");

  return (
    <>
      <IconButton onClick={handleClickBack} sx={{ visibility: loading ? "hidden" : "visible" }}>
        <ArrowBackIosIcon />
      </IconButton>
      <Stack spacing={3} width={"100%"}>
        <LoadingSkeleton loading={loading}>
          <Box>
            <Typography variant={"h4"} fontWeight={600}>
              Park Event
            </Typography>
            <Typography variant={"subtitle1"} fontSize={"12px"}>
              A detailed description of a park event
            </Typography>
          </Box>
        </LoadingSkeleton>
        <InvoiceStatusStepper invoiceId={invoiceId} />
        <InvoiceSessionImageCarousel invoiceId={invoiceId} transactionId={transactionId} />
        <InvoiceSessionDetails transactionId={transactionId} />
        <TransactionTimeAndRateBreakdown
          loading={loading}
          isFreeTransaction={isFreeTransaction}
          policyCompliance={transactionData?.transaction?.park?.policyRateDetails}
        />
        <TransactionTotal
          loading={loading}
          isFreeTransaction={isFreeTransaction}
          amountBilled={sumBy(transactionData?.transaction?.park?.policyRateDetails || [], "price")}
          sessionDurationSeconds={
            transactionData?.transaction?.park
              ? differenceInSeconds(
                  new Date(transactionData.transaction.park?.endTime || 0),
                  new Date(transactionData.transaction.park?.startTime || 0),
                )
              : 0
          }
          currencySymbol={"$"}
        />
      </Stack>
    </>
  );
};

export default InvoiceTransaction;
