import React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { RealTimeOccupancySpaceStatus } from "../RealTimeOccupancySpaceStatusIndicator";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { alpha } from "@mui/material/styles";

export type RealTimeOccupancyMapLegendStatus = {
  [key in RealTimeOccupancySpaceStatus]?: boolean;
};

const realTimeOccupancyMapFilterStatuses: {
  [key in RealTimeOccupancySpaceStatus]: string;
} = {
  available: "Available",
  occupied: "Occupancy Detected",
  occluded: "Occlusion Detected",
  violation: "Violation Detected",
  doubleparked: "Double Park",
  zoneSelected: "Selected",
  selected: "Selected",
};

export const RealTimeOccupancyMapFilter: React.FC<RealTimeOccupancyMapLegendProps> = ({
  loading,
  filterStatuses,
  onChangeFilterStatuses,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "realtime-occupancy-map-filter-popover" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const filterApplied = filterStatuses.some((status) => !Object.values(status)[0]);

  return loading ? (
    <Skeleton variant="rectangular" width={"48px"} height={"48px"}>
      <Box width={"48px"} height={"48px"} />
    </Skeleton>
  ) : (
    <Box>
      <Button
        aria-describedby={id}
        sx={(theme) => ({
          p: 1.5,
          borderRadius: "50%",
          width: "48px",
          height: "48px",
          minWidth: "48px",
          position: "relative",
          backgroundColor: open ? alpha(theme.palette.primary.main, 0.08) : "transparent",
        })}
        onClick={handleClick}
      >
        <FilterAltIcon sx={{ color: open ? "primary.main" : "text.secondary", cursor: "pointer" }} />
        {filterApplied && (
          <Box
            sx={{
              position: "absolute",
              width: "8px",
              height: "8px",
              right: "5px",
              top: "11px",
              borderRadius: "50%",
              backgroundColor: "primary.main",
            }}
          />
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Box py={1}>
          {filterStatuses.map((statusObj, index) => {
            const status = Object.keys(statusObj)[0] as RealTimeOccupancySpaceStatus;
            const statusValue = statusObj[status];
            return (
              <Box
                key={status}
                px={2}
                py={0.75}
                display={"flex"}
                gap={1}
                alignItems={"center"}
                onClick={() => {
                  const newFilterStatuses = [...filterStatuses];
                  newFilterStatuses[index][status] = !statusValue;
                  onChangeFilterStatuses(newFilterStatuses);
                }}
              >
                <Checkbox checked={statusValue} sx={{ p: 0 }} />
                <Typography variant="body1" letterSpacing={"0.15px"}>
                  {realTimeOccupancyMapFilterStatuses[status]}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Popover>
    </Box>
  );
};

export interface RealTimeOccupancyMapLegendProps {
  /** indicates the loading status of the component */
  loading: boolean;
  /** Array of filters which are currently applied. The array will take the structure defined below
   * [
   *  { available: true },
   *  { occupied: false },
   *  ...
   *  { occluded: true },
   * ]
   */
  filterStatuses: RealTimeOccupancyMapLegendStatus[];
  /** handler fired when filters change */
  onChangeFilterStatuses: (newFilterStatuses: RealTimeOccupancyMapLegendStatus[]) => void;
}

export default RealTimeOccupancyMapFilter;
