import { useAuth0 } from "@auth0/auth0-react";
import { UserClaims } from "common/models";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { FullScreenLoader } from "@app.automotus.io/components/FullScreenLoader";
import { Navigate } from "react-router-dom";
import React from "react";

export const CurbsuiteLogin: React.FC = () => {
  const { userProfile, loading } = useUserProfile();
  return (
    <FullScreenLoader loading={loading}>
      {userProfile ? <Navigate to={"/dashboard"} /> : <LoginWhenNotAuthenticated />}
    </FullScreenLoader>
  );
};

const LoginWhenNotAuthenticated: React.FC = () => {
  const { loginWithRedirect } = useAuth0<UserClaims>();
  loginWithRedirect();
  return null;
};

export default CurbsuiteLogin;
