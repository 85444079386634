import React from "react";
import { useLocation, useParams, Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useQuery } from "@apollo/client";
import Box from "@mui/material/Box";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { GET_CITATION_BY_ID, GetCitationByIdData, GetCitationByIdVars } from "common/graphql/queries/GetCitation";
import { CitationStatusStepper } from "@app.automotus.io/components/CitationStatusStepper";
import { CitationDetails } from "@app.automotus.io/components/CitationDetails";
import { CitationImageCarousel } from "@app.automotus.io/components/CitationImageCarousel";
import VehicleInvoiceCardStatus from "@app.automotus.io/components/VehicleInvoice/VehicleInvoiceCardStatus";
import ViolationPenalty from "@app.automotus.io/components/ViolationPenalty";
import CitationGrandTotal from "@app.automotus.io/components/CitationGrandTotal";
import CitationTransaction from "@app.automotus.io/components/CitationTransaction";
import CitationTransactionRateBreakdown from "@app.automotus.io/components/CitationTransactionRateBreakdown";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { CitationsLocationState } from "./index";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export const Citation: React.FC = () => {
  const { citationId = "" } = useParams<{ citationId: string }>();
  const { data, loading, error, refetch } = useQuery<GetCitationByIdData, GetCitationByIdVars>(GET_CITATION_BY_ID, {
    variables: { id: citationId },
    onError(err) {
      console.error(`failed to fetch citation by id ${citationId}`, err);
    },
  });

  const location = useLocation();

  const locationState = location.state as CitationsLocationState | null;
  const backButtonLink = `..?page=${locationState?.citationTablePage || 0}&pageSize=${
    locationState?.citationTablePageSize || 31
  }${locationState?.citationTableSortStr ? `&sort=${encodeURIComponent(locationState?.citationTableSortStr)}` : ""}`;

  if (error) {
    return (
      <>
        <IconButton component={RouterLink} to={backButtonLink}>
          <ArrowBackIosIcon />
        </IconButton>
        <Stack spacing={3} width={"100%"}>
          <Box>
            <LocalLevelError onTryAgain={refetch} />
          </Box>
        </Stack>
      </>
    );
  }

  return (
    <>
      <IconButton component={RouterLink} to={backButtonLink}>
        <ArrowBackIosIcon />
      </IconButton>
      <Stack spacing={3} width={"100%"}>
        <Box>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"h4"} fontWeight={600} letterSpacing={0.25}>
              Citation ID: {data?.citation?.citationNumber}
            </Typography>
          </LoadingSkeleton>
          <LoadingSkeleton loading={loading}>
            <Typography variant={"subtitle1"} fontSize={12} letterSpacing={0.4} color={"text.disabled"}>
              A detailed description of a citation
            </Typography>
          </LoadingSkeleton>
        </Box>
        {data?.citation?.voidedAt && (
          <Box>
            <VehicleInvoiceCardStatus
              message={<Typography>This citation has been voided as the result of a resolved dispute.</Typography>}
            />
          </Box>
        )}
        {data?.citation?.dispute?.status === "pending" && (
          <Box>
            <VehicleInvoiceCardStatus
              status="warning"
              message={
                <span>
                  We’re currently reviewing this citation. Please{" "}
                  <Link
                    sx={{ color: "inherit", textDecorationColor: "inherit" }}
                    href={`mailto:michelle@automotus.co?subject=[CurbSuite Support Request] Citation Under Review #${data?.citation?.citationNumber}`}
                  >
                    contact us
                  </Link>{" "}
                  with questions.
                </span>
              }
            />
          </Box>
        )}
        {!(data?.citation?.voidedAt || data?.citation?.dispute?.status === "pending") && (
          <CitationStatusStepper citationId={citationId} />
        )}
        <CitationImageCarousel citationId={citationId} />
        <CitationDetails citationId={citationId} />
        {data?.citation?.isSessionTransactionBillable && <CitationTransaction citationId={citationId} />}
        {data?.citation?.isSessionTransactionBillable && (
          <CitationTransactionRateBreakdown
            loading={loading}
            policyRateDetails={data?.citation?.sessionTransaction?.park?.policyRateDetails}
          />
        )}
        <ViolationPenalty citationId={citationId} />
        {data?.citation?.isSessionTransactionBillable && <CitationGrandTotal citationId={citationId} />}
      </Stack>
    </>
  );
};

export default Citation;
