import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
  GridSortModel,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { GET_INVOICES, GetInvoicesData, GetInvoicesVars, InvoiceOrderBy, InvoiceSummary } from "common/graphql";
import { useQuery } from "@apollo/client";
import { TableErrorOverlay } from "@app.automotus.io/components/PayeeTables/TableErrorOverlay";
import { TableLoadingOverlay } from "@app.automotus.io/components/PayeeTables/TableLoadingOverlay";
import { formatCurrency } from "common/format";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { CustomPagination } from "@app.automotus.io/components/CustomPagination";
import format from "date-fns/format";
import { parseSortModel, serializeSortModel } from "common/graphql/queryString";

export interface InvoicesLocationState {
  invoiceTablePage?: number;
  invoiceTablePageSize?: number;
  invoiceTableSortStr?: string;
}

const DEFAULT_PAGE_SIZE = 31;

export const Invoices: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: "0",
    pageSize: `${DEFAULT_PAGE_SIZE}`,
  });
  const navigate = useNavigate();

  const page = Number(searchParams.get("page") || 1);
  const handlePageChange = (newPage: number) => {
    searchParams.set("page", `${newPage}`);
    setSearchParams(searchParams);
  };

  const pageSize = Number(searchParams.get("pageSize") || DEFAULT_PAGE_SIZE);
  const handlePageSizeChange = (newPageSize: number) => {
    searchParams.set("pageSize", `${newPageSize}`);
    setSearchParams(searchParams);
  };

  const sortStr = searchParams.get("sort") || "noticeDate+desc";
  const sortModel: GridSortModel = parseSortModel(sortStr);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    searchParams.set("sort", serializeSortModel(newSortModel));
    setSearchParams(searchParams);
  };

  const handleRowClick = ({ row }: GridRowParams<InvoiceSummary>) => {
    navigate(`${row.id}`, {
      state: {
        invoiceTablePage: page,
        invoiceTablePageSize: pageSize,
        invoiceTableSortStr: sortStr,
      },
    });
  };

  return (
    <Stack spacing={3} width={"100%"}>
      <Box>
        <Typography variant={"h4"} fontWeight={600}>
          Invoices
        </Typography>
        <Typography variant={"subtitle1"} fontSize={"12px"}>
          Recent invoices can take up to 24 hours to process.
        </Typography>
      </Box>
      <InvoiceTable
        page={page}
        pageSize={pageSize}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onRowClick={handleRowClick}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
      />
    </Stack>
  );
};

export const InvoiceTableToolbar: React.FC<InvoiceTableToolbarProps> = ({ exportFileName }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 52,
        px: 1.25,
        bgcolor: (theme) => theme.palette.background.paper,
      }}
    >
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ fileName: exportFileName }}
        excelOptions={{ fileName: exportFileName }}
        sx={{
          fontSize: "16px",
          fontWeight: 400,
        }}
      />
    </Box>
  );
};

export interface InvoiceTableToolbarProps {
  exportFileName: string;
}

export const InvoiceTable: React.FC<InvoiceTableProps> = ({
  page,
  onPageChange,
  pageSize,
  onPageSizeChange,
  onRowClick,
  sortModel,
  onSortModelChange,
}) => {
  const navigate = useNavigate();
  const INVOICE_TABLE_COLUMNS = useMemo(
    () => [
      {
        field: "index",
        headerName: "Invoice Number",
        width: 206,
        align: "left",
        headerAlign: "left",
        valueFormatter({ value }) {
          return `${value}`.padStart(6, "0");
        },
        sortingOrder: ["desc", "asc"],
      } as GridColDef<InvoiceSummary, number, string>,
      {
        field: "licensePlate",
        headerName: "License Plate",
        width: 166,
        valueGetter({ row }) {
          return `${row.registeredVehicle.licensePlateStateCode} ${row.registeredVehicle.licensePlateNumber}`;
        },
        sortable: false,
      } as GridColDef<InvoiceSummary, string>,
      {
        field: "noticeDate",
        headerName: "Notice Date",
        type: "date",
        width: 159,
        valueGetter({ row }) {
          return new Date(row.noticeSentAt);
        },
        sortingOrder: ["desc", "asc"],
      } as GridColDef<InvoiceSummary, Date>,
      {
        field: "paidDate",
        headerName: "Paid Date",
        type: "date",
        width: 144,
        valueGetter({ row }) {
          return row.paymentTransaction ? new Date(row.paymentTransaction.createdAt) : null;
        },
        valueFormatter({ value }) {
          return value ? format(value, "MM/dd/yyyy") : "";
        },
        sortingOrder: ["desc", "asc"],
      } as GridColDef<InvoiceSummary, Date | null, string>,
      {
        field: "status",
        headerName: "Status",
        width: 169,
        valueGetter({ row }) {
          if (row.paymentTransaction) {
            return "Paid";
          } else if (row?.dispute?.status === "pending") {
            return "Under Review";
          } else if (row.voidedAt) {
            return "Void";
          } else if (new Date(row.dueAt).getTime() < Date.now()) {
            return "Overdue";
          } else {
            return "Pending";
          }
        },
        sortable: false,
      } as GridColDef<InvoiceSummary, string>,
      {
        field: "amount",
        headerName: "Amount",
        width: 151,
        sortable: false,
        valueGetter({ row }) {
          return row.transactionAmountDue + row.adminFeeDue - row.adminFeeAmountWaived;
        },
        valueFormatter({ value }) {
          return `$${formatCurrency(value)}`;
        },
      } as GridColDef<InvoiceSummary, number, string>,
      {
        field: "actions",
        type: "actions",
        sortable: false,
        getActions: (params) => [
          <GridActionsCellItem
            label={"View Invoice"}
            icon={<ArrowForwardIosIcon sx={{ color: (theme) => theme.palette.action.active }} />}
            onClick={() => navigate(`${params.row.id}`)}
          />,
        ],
      } as GridActionsColDef,
    ],
    [navigate],
  );
  const offset = page * pageSize;
  const limit = pageSize;

  const parseOrderBy = (sortModel: GridSortModel): InvoiceOrderBy[] => {
    const { field: sortField, sort: sortDirection } = sortModel[0];
    switch (sortField) {
      case "index":
        return [{ index: sortDirection || "asc" }];
      case "paidDate":
        return [
          { payment_transaction: { created_at: sortDirection === "asc" ? "asc_nulls_last" : "desc_nulls_last" } },
        ];
      default:
        return [{ notice_sent_at: sortDirection || "desc" }];
    }
  };

  const { data, loading, error, refetch } = useQuery<GetInvoicesData, GetInvoicesVars>(GET_INVOICES, {
    variables: {
      offset,
      limit,
      orderBy: parseOrderBy(sortModel),
    },
    onError(err) {
      console.error("failed to fetch invoices", err);
    },
  });
  const exportFileName = `invoices_${(Math.random() * 1e6).toFixed(0)}`;

  return (
    <Paper sx={{ borderRadius: 3, maxWidth: 1084, height: 876 }}>
      <DataGridPro
        components={{
          ErrorOverlay: TableErrorOverlay,
          LoadingOverlay: TableLoadingOverlay,
          Toolbar: InvoiceTableToolbar,
          Pagination: CustomPagination,
        }}
        componentsProps={{
          toolbar: {
            exportFileName: exportFileName,
          },
          errorOverlay: {
            onTryAgain: () => refetch(),
          },
          loadingOverlay: {
            numColumns: INVOICE_TABLE_COLUMNS.length,
          },
        }}
        columns={INVOICE_TABLE_COLUMNS}
        rows={data?.invoices || []}
        rowCount={data?.invoicesAggregate.aggregate.count || 0}
        loading={loading}
        disableMultipleColumnsSorting
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        error={error}
        pagination
        page={page}
        onPageChange={onPageChange}
        pageSize={pageSize}
        onPageSizeChange={onPageSizeChange}
        paginationMode={"server"}
        rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
        onRowClick={onRowClick}
        sortingMode={"server"}
        sortModel={sortModel}
        initialState={{
          sorting: {
            sortModel: [{ field: "noticeDate", sort: "desc" }],
          },
        }}
        onSortModelChange={onSortModelChange}
        sx={{
          ".MuiDataGrid-columnHeaderTitle": {
            fontSize: "18px",
            fontWeight: 700,
            lineHeight: "24px",
            letterSpacing: 0.17,
          },
        }}
      />
    </Paper>
  );
};

export interface InvoiceTableProps {
  page: number;
  onPageChange: (page: number) => void;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
  onRowClick: (params: GridRowParams<InvoiceSummary>) => void;
  sortModel: GridSortModel;
  onSortModelChange: (sortModel: GridSortModel) => void;
}

export default Invoices;
