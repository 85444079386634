import React from "react";
import Tooltip from "@mui/material/Tooltip";
import CachedIcon from "@mui/icons-material/Cached";

export const SyncInProgressTooltip: React.FC<SyncInProgressTooltipProps> = ({ toolTipPosition }) => {
  return (
    <Tooltip title="Syncing in progress" placement={toolTipPosition || "right"} arrow>
      <CachedIcon sx={{ fontSize: "16px", color: "success.dark" }} />
    </Tooltip>
  );
};

interface SyncInProgressTooltipProps {
  toolTipPosition?: "left" | "right" | "top" | "bottom";
}

export default SyncInProgressTooltip;
