import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

export const RealTimeOccupancyOfflineZonesAlert: React.FC<RealTimeOccupancyOfflineZonesAlertProps> = ({
  numZonesOffline,
  onClickViewZones,
}) => {
  return (
    <Alert
      severity="warning"
      action={
        <Button
          variant="text"
          sx={{
            color: "inherit",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            px: 0.625,
            py: 0.5,
            textTransform: "none",
          }}
          onClick={onClickViewZones}
        >
          View Zones
        </Button>
      }
      sx={{
        width: "100%",
        py: 0,
        ".MuiAlert-message": {
          py: 1.125,
        },
        ".MuiAlert-action": {
          mr: 0,
        },
        ".MuiSvgIcon-root": {
          fontSize: "22px",
          color: "warning.main",
        },
        alignItems: "center",
      }}
    >
      <Typography variant="body4">
        <b>{numZonesOffline}</b> zones are currently experiencing a power loss. They may not be visible on the map.
      </Typography>
    </Alert>
  );
};

export interface RealTimeOccupancyOfflineZonesAlertProps {
  // The number of offline zones
  numZonesOffline: number;
  // Click handler fired when a user clicks the View Zones button
  onClickViewZones: () => void;
}

export default RealTimeOccupancyOfflineZonesAlert;
